import { createSlice } from "@reduxjs/toolkit";

import { contactUs } from "./supportThunk";

export interface SupportInitialStateProps {
  isLoading: boolean;
}

const SupportInitialState: SupportInitialStateProps = {
  isLoading: false,
};

const supportSlice = createSlice({
  name: "support",
  initialState: SupportInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For submitting contact form
    builder
      .addCase(contactUs.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(contactUs.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const supportActions = supportSlice.actions;

export default supportSlice;
