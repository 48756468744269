import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import styles from "../styles/PDFViewer.module.scss";

interface PDFViewerModalProps {
  pdfUrl: string;
  open: boolean;
  toggle: () => void;
}

export default function PDFViewerModal({ pdfUrl, open, toggle }: PDFViewerModalProps) {
  return open ? (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div className={styles["pdf-viewer"]}>
          <div className={styles["pdf-container"]}>{pdfUrl && <Viewer fileUrl={pdfUrl.replace("http://", `${window.location.protocol}//`)} />}</div>
        </div>
      </Worker>
    </>
  ) : (
    <></>
  );
}
