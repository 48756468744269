import React from "react";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { useHandleRouteClick } from "../routes/hooks";
import { useAppSelector } from "../store/hooks";
import Footer from "./Footer";

const NavbarMobile = () => {
  const location = useLocation();
  const handleRouteClick = useHandleRouteClick();
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);
  const [openNavMenu, setOpenNavMenu] = React.useState(false);

  const toggleNavMenu = () => {
    setOpenNavMenu((prev) => !prev);
  };

  return (
    <div
      className="d-md-none flex-row justify-content-end my-1 navbar gap-2 d-flex"
      style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { color: "white" } : {}}
    >
      <button onClick={toggleNavMenu} className="btn">
        <RxHamburgerMenu size={32} />
      </button>
      {openNavMenu && (
        <div className="nav-menu-mobile p-3">
          <div className="d-flex flex-column align-items-end gap-2">
            <button onClick={toggleNavMenu} className="btn">
              <RxCross2 size={42} />
            </button>
            <div className="w-100 d-flex justify-content-between">
              <h4
                className={`d-blocl d-md-none m-0 font-didot-bold`}
                onClick={() => handleRouteClick(routes.home.path)}
                style={{ cursor: "pointer" }}
              >
                Home
              </h4>
              <h4
                className={`d-blocl d-md-none m-0 font-didot-bold`}
                onClick={() => handleRouteClick(routes.logout.path)}
                style={{ cursor: "pointer" }}
              >
                Log Out
              </h4>
            </div>
          </div>
          <div className="nav-menu-items">
            {isAuthenticated ? (
              <>
                <nav className={location.pathname.includes(routes.home.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.home.path}>TICKETS</a>
                </nav>
                <nav className={location.pathname.includes(routes.magazine.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.magazine.path}>MAGAZINE</a>
                </nav>
                <nav className={`${location.pathname.includes(routes.about.path) ? "nav-menu active" : "nav-menu"}`}>
                  <a href={routes.about.path}>THE SHOW</a>
                </nav>
                <nav className={`${location.pathname.includes(routes.press.path) ? "nav-menu active" : "nav-menu"}`}>
                  <a href={routes.press.path}>PRESS</a>
                </nav>
                <nav className={location.pathname.includes(routes.dreamHub.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.dreamHub.path}>DREAM HUB</a>
                </nav>
                <nav className={location.pathname.includes(routes.portfolio.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.portfolio.path}>DESIGN SERVICES</a>
                </nav>
                {user?.is_sponsor && (
                  <nav className={location.pathname.includes(routes.sponsorGuests.path) ? "nav-menu active" : "nav-menu"}>
                    <a href={routes.sponsorGuests.path}>GUESTS</a>
                  </nav>
                )}
                <nav className={`${location.pathname.includes(routes.contact.path) ? "nav-menu active" : "nav-menu"}`}>
                  <a href={routes.contact.path}>CONTACT</a>
                </nav>
                <nav className={`${location.pathname.includes(routes.account.path) ? "nav-menu active" : "nav-menu"}`}>
                  <a href={routes.account.path}>ACCOUNT</a>
                </nav>
              </>
            ) : (
              <>
                <nav className={location.pathname.includes(routes.portfolio.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.portfolio.path}>DESIGN SERVICES</a>
                </nav>
                <nav className={location.pathname.includes(routes.about.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.about.path}>THE SHOW</a>
                </nav>
                <nav className={location.pathname.includes(routes.press.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.press.path}>PRESS</a>
                </nav>
                <nav className={location.pathname.includes(routes.magazine.path) ? "nav-menu active" : "nav-menu"}>
                  <a href={routes.magazine.path}>MAGAZINE</a>
                </nav>
              </>
            )}
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

const NavbarDesktop = () => {
  const location = useLocation();
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);

  return (
    <div
      className="d-md-flex flex-row justify-content-between my-4 navbar gap-2 d-none"
      style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { color: "white" } : {}}
    >
      {isAuthenticated ? (
        <>
          <nav className={location.pathname.includes(routes.home.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.home.path}>TICKETS</Link>
          </nav>
          <nav className={location.pathname.includes(routes.magazine.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.magazine.path}>MAGAZINE</Link>
          </nav>
          <nav className={`${location.pathname.includes(routes.about.path) ? "nav-menu active" : "nav-menu"} d-none d-md-block`}>
            <Link to={routes.about.path}>THE SHOW</Link>
          </nav>
          <nav className={`${location.pathname.includes(routes.press.path) ? "nav-menu active" : "nav-menu"} d-none d-md-block`}>
            <Link to={routes.press.path}>PRESS</Link>
          </nav>
          <nav className={location.pathname.includes(routes.dreamHub.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.dreamHub.path}>DREAM HUB</Link>
          </nav>
          {user?.is_sponsor && (
            <nav className={location.pathname.includes(routes.sponsorGuests.path) ? "nav-menu active" : "nav-menu"}>
              <Link to={routes.sponsorGuests.path}>GUESTS</Link>
            </nav>
          )}
          <nav className={location.pathname.includes(routes.portfolio.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.portfolio.path}>DESIGN SERVICES</Link>
          </nav>
          <nav className={`${location.pathname.includes(routes.contact.path) ? "nav-menu active" : "nav-menu"} d-none d-md-block`}>
            <Link to={routes.contact.path}>CONTACT</Link>
          </nav>
          <nav className={`${location.pathname.includes(routes.account.path) ? "nav-menu active" : "nav-menu"}`}>
            <Link to={routes.account.path}>ACCOUNT</Link>
          </nav>
        </>
      ) : (
        <>
          <nav className={location.pathname.includes(routes.portfolio.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.portfolio.path}>DESIGN SERVICES</Link>
          </nav>
          <nav className={location.pathname.includes(routes.about.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.about.path}>THE SHOW</Link>
          </nav>
          <nav className={location.pathname.includes(routes.press.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.press.path}>PRESS</Link>
          </nav>
          <nav className={location.pathname.includes(routes.magazine.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.magazine.path}>MAGAZINE</Link>
          </nav>
          {/* <nav className={location.pathname.includes(routes.contact.path) ? "nav-menu active" : "nav-menu"}>
            <Link to={routes.contact.path}>CONTACT</Link>
          </nav> */}
        </>
      )}
    </div>
  );
};

export default function Navbar() {
  return (
    <>
      <NavbarMobile />
      <NavbarDesktop />
    </>
  );
}
