import React from "react";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPress, getPressTexts } from "../../store/portfolio/portfolioThunk";
import styles from "../../styles/Press.module.scss";
import { convertDateTimeToMonthYear } from "../../utils/helper";

interface PressPageItem {
  pageNumber: number;
  component: React.ReactElement;
}

interface PressThumbnailsProps {
  presses: {
    id: number;
    link: string;
    image: string;
    label: string;
    date: string;
  }[];
}

const PressThumbnails = ({ presses }: PressThumbnailsProps) => {
  return (
    <div className="row justify-content-center">
      {presses.map((data) => {
        return (
          <div className="col-6 col-md-6 text-start text-md-center mb-5" key={data.id}>
            <a target="_blank" rel="noreferrer" href={data.link}>
              <div>
                <img
                  loading="lazy"
                  alt="portfolio"
                  src={data.image || "/placeholder-vertical.jpg"}
                  // style={{ height: "18rem", width: "autp", objectFit: "cover", border: "1px solid black" }}
                  className={`mb-3 img-fluid ${styles["press-image"]}`}
                />
                <p className="m-0 font-didot-bold">{data.label}</p>
                <p className="m-0 font-didot fw-medium">{convertDateTimeToMonthYear(data.date)}</p>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

interface PressTextProps {
  pressText: {
    id: number;
    label: string;
    description: string;
    author: string;
  };
}

const PressText = ({ pressText }: PressTextProps) => {
  return (
    <div className="text-center col-md-6 offset-md-3">
      <h3 className="font-didot my-4">&#8220;{pressText.description} &#8221;</h3>
      {pressText.author && <h4 className="font-didot my-5 fw-light">-&nbsp;{pressText.author}</h4>}
      <p className="font-graebenbach">{pressText.description}</p>
    </div>
  );
};

export default function PressPage() {
  const handleRouteClick = useHandleRouteClick();
  const dispatch = useAppDispatch();
  const { presses, pressTexts, isLoading } = useAppSelector((state) => state.portfolio);
  const [pages, setPages] = React.useState<Array<PressPageItem>>([]);
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(0);

  const handleNextPage = () => {
    if (pages && currentPageNumber + 1 < pages.length) {
      // Going to next step is only allowed if we are not in the last step
      setCurrentPageNumber((prev) => prev + 1);
    } else {
      // If we are after the last step
      setCurrentPageNumber(0);
    }
  };

  const handlePreviousPage = () => {
    if (currentPageNumber > 0) {
      // Going to previous step is only allowed if we are not in the first step
      setCurrentPageNumber((prev) => prev - 1);
    } else {
      setCurrentPageNumber(pages.length - 1);
    }
  };
  React.useEffect(() => {
    if (pressTexts.length <= 0) {
      dispatch(getPressTexts());
    }
    if (presses.length <= 0) {
      dispatch(getPress());
    }
  }, []);

  React.useEffect(() => {
    setPages((prev) => {
      const tempPages = pressTexts.map((data, index) => {
        return {
          pageNumber: index + 2, // Adding 2 because we laready have an item in pages and index starts with 0
          component: <PressText pressText={data} />,
        };
      });
      return [
        {
          pageNumber: 1,
          component: <PressThumbnails presses={presses} />,
        },
        ...tempPages,
      ];
    });
  }, [presses, pressTexts]);

  return (
    <div className={`${styles["press"]} my-4 py-4 ${styles["page-curve"]} d-flex flex-column justify-content-between`}>
      {/* <div>
        <div className="mb-5">
          <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.home.path)} style={{ cursor: "pointer", textAlign: "left" }}>
            Home
          </h4>
        </div>
      </div> */}
      <div>{pages[currentPageNumber]?.component}</div>
      <div>
        <div className="d-flex center">
          <button className="my-3 border-0 bg-transparent w-auto" type="button" onClick={() => handlePreviousPage()}>
            <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(-180deg)" }} />
          </button>
          <h2 className="font-didot fst-italic mx-4">{`${currentPageNumber + 1}/${pages.length}`}</h2>
          <button className="my-3 border-0 bg-transparent w-auto" type="button" onClick={() => handleNextPage()}>
            <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
          </button>
        </div>
      </div>
    </div>
  );
}
