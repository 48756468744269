import React from "react";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getMyDetail, saveConsentAgreement, updateMyDetail } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getTermsAndConditionsText } from "../../../store/tickets/ticketsThunk";
import styles from "../../../styles/TicketQuestionnaire.module.scss";
import { FOOD_TYPE } from "../../../utils/constants";

interface TicketQuestionnaireProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

interface QuestionInterface {
  id: number;
  component: React.ReactElement;
}

interface ConsentAgreementProps {
  onSubmit: () => void;
}

interface FoodChoiceProps {
  onSubmit: () => void;
}

const ConsentAgreement = ({ onSubmit }: ConsentAgreementProps) => {
  const dispatch = useAppDispatch();
  const { termsAndConditionsPage } = useAppSelector((state) => state.tickets);

  React.useEffect(() => {
    dispatch(getTermsAndConditionsText());
  }, []);

  return (
    <div className={`text-center ${styles["questionnaire-sub-container"]}`}>
      <h4 className="font-didot-bold mb-4">HXS Terms and Conditions</h4>
      <div>
        <div className="mb-4 col-md-6 offset-md-3 font-didot">
          <div dangerouslySetInnerHTML={{ __html: termsAndConditionsPage?.content }}></div>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            dispatch(saveConsentAgreement()).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
                dispatch(getMyDetail());
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          I AGREE
        </button>
      </div>
    </div>
  );
};

const FoodChoice = ({ onSubmit }: FoodChoiceProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className={`text-center ${styles["questionnaire-sub-container"]}`}>
      <h4 className="font-didot-bold mb-4">Food</h4>
      <div>
        <div className="mb-4 col-md-4 offset-md-4">
          <h6 className="m-0 w-100 font-didot">
            Upon arrival you will be taken to your reservation and served with delicacies while you sit in silence experiencing the show. There is no
            additional cost to you.
          </h6>
        </div>
        <div className="mb-4 col-md-4 offset-md-4">
          <h6 className="m-0 w-100 font-didot">Please pre-select your dining preference. You can always change your preference at the event.:</h6>
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center">
        <button
          type="button"
          onClick={() => {
            dispatch(updateMyDetail({ food: FOOD_TYPE.NON_VEGETARIAN })).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          NON-VEGETARIAN
        </button>
        <button
          type="button"
          onClick={() => {
            dispatch(updateMyDetail({ food: FOOD_TYPE.VEGETARIAN })).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          VEGETARIAN
        </button>
      </div>
    </div>
  );
};

export default function TicketQuestionnaire({ handleNextStep: nextStep, isPartnerPortal = false }: TicketQuestionnaireProps) {
  const { user } = useAppSelector((state) => state.auth);
  const handleRouteClick = useHandleRouteClick();
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const questionSteps: QuestionInterface[] = [
    {
      id: 1,
      component: (
        <FoodChoice
          onSubmit={() => {
            handleNextStep();
          }}
        />
      ),
    },
    {
      id: 2,
      component: <ConsentAgreement onSubmit={() => nextStep()} />,
    },
  ];

  React.useEffect(() => {
    if (user && user?.is_agreed_hxs_house_rules) {
      if (!user?.food) {
        // onSubmit();
        // setCurrentStep(questionSteps[questionSteps.length - 1].id);
      }
    }
  }, []);

  return (
    <div>
      <div className="my-3 d-flex justify-content-between">
        <div>
          <a href={routes.home.path}>
            <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
              Home
            </h4>
          </a>
        </div>
        <div>
          <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
            Log Out
          </h4>
        </div>
      </div>
      <div className={styles["questionnaire"]}>{questionSteps.find((s) => s.id === currentStep)?.component}</div>
    </div>
  );
}
