import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI } from "../../axios/utils";
import { GET_MAGAZINES_API, GET_PLATFORM_PORTFOLIO_API, GET_PRESSES_PORTFOLIO_API, GET_PRESS_TEXT_PORTFOLIO_API } from "../../utils/constants";

export const getPlatformPortfolio = createAsyncThunk("portfolio/getPlatformPortfolio", async () => {
  return getAPI(GET_PLATFORM_PORTFOLIO_API);
});

export const getPress = createAsyncThunk("portfolio/getPress", async () => {
  return getAPI(GET_PRESSES_PORTFOLIO_API);
});

export const getMagazines = createAsyncThunk("portfolio/getMagazines", async () => {
  return getAPI(GET_MAGAZINES_API);
});

export const getPressTexts = createAsyncThunk("portfolio/getPressTexts", async () => {
  return getAPI(GET_PRESS_TEXT_PORTFOLIO_API);
});
