import React from "react";
import styles from "../../styles/FileUploadAnswer.module.scss";
import FileDetailCard from "../FileDetailCard";
import FileUpload from "../FileUpload";

export type FileData = {
  fileObj: File;
  description?: string;
  fileUrl?: string;
  file?: string;
  id?: number;
};

export interface FilesSelectAnswerProps {
  getFiles: (value: FileData[]) => any;
  userFileAnswers: FileData[];
}

export default function FilesSelectAnswer({ getFiles, userFileAnswers }: FilesSelectAnswerProps) {
  const [files, setFiles] = React.useState<FileData[]>(userFileAnswers || []);

  const handleFileChange = (fileDataObj: FileData) => {
    setFiles((prev) => {
      return [...prev, fileDataObj];
    });
  };

  const handleChangeDescription = (description: string, index: number) => {
    setFiles((prev) => {
      const tempFiles = prev;
      tempFiles[index].description = description;
      return tempFiles;
    });
  };

  React.useEffect(() => {
    getFiles(files);
  }, [files]);

  return (
    <div className={`${styles["file-upload-answer"]}`}>
      {/* <p>Attach any drawings you may have of your existing or future space</p> */}
      <div style={{ textAlign: "center" }}>
        <FileUpload
          getFile={(fileDataObj: FileData) =>
            handleFileChange({
              fileObj: fileDataObj.fileObj,
              description: "",
              fileUrl: fileDataObj.fileUrl,
            })
          }
        />
      </div>
      <div className="d-flex flex-column gap-4 mt-4">
        {files.map((f, index) => {
          return (
            <div key={index}>
              <FileDetailCard
                fileDataObj={f}
                handleChangeDescription={(description: string) => handleChangeDescription(description, index)}
                handleRemove={() => setFiles((prev) => prev.filter((_, idx) => idx !== index))}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
