import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppSelector } from "../../../store/hooks";
import styles from "../../../styles/TicketHome.module.scss";

interface TicketHomeProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

export default function TicketHome({ handleNextStep, isPartnerPortal = false }: TicketHomeProps) {
  const handleRouteClick = useHandleRouteClick();
  const { user } = useAppSelector((state) => state.auth);

  return (
    <div>
      {!isPartnerPortal && (
        <div className="my-3 d-flex justify-content-between">
          <div>
            <h4 className="font-didot-bold">Welcome</h4>
            <h4 className="font-didot-bold">{user?.full_name}</h4>
          </div>
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        </div>
      )}
      <div className={`${styles["main-image"]} center`}>
        <div className="d-flex flex-column justify-content-center" style={{ cursor: "pointer" }} onClick={handleNextStep}>
          <button className="px-md-4" style={{ background: "#9a3a2ec7", color: "white", whiteSpace: "nowrap" }}>
            GET MY TICKET
          </button>
          <img src="/arrow_red.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
        </div>
      </div>
    </div>
  );
}
