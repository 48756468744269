interface YouTubePopupProps {
  open: boolean;
  toggleModal: () => void;
  streamURL: string;
}

export default function YouTubePopup({ open, toggleModal, streamURL }: YouTubePopupProps) {
  return (
    <>
      <div className="modal fade m-0 p-0" id="promo_modal" tabIndex={-1} aria-labelledby="promo_modal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-black font-didot-bold" id="promoModalLabel">
                Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  toggleModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <iframe
                src={open ? streamURL.replaceAll("shorts", "embed") : ""}
                title="YouTube video player"
                frameBorder="0"
                style={{ width: "100%", height: "80vh" }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              {/* <iframe
                width="100%"
                height="420"
                src={open ? streamURL : ""}
                title="HXS Live Streaming"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
