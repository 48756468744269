import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getSponsorDetailAPI } from "../../../store/settings/settingsThunk";

interface EventDetailModalProps {
  open: boolean;
  toggleModal: () => void;
  data?: Record<string, any>;
}

export default function EventDetailModal({ open, toggleModal }: EventDetailModalProps) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [sponsorName, setSponsorName] = React.useState("");
  const { settings } = useAppSelector((state) => state.tickets);

  React.useEffect(() => {
    dispatch(getSponsorDetailAPI(searchParams.get("ref_code") || "")).then((res) => {
      if (res.payload?.status === 200) {
        setSponsorName(res.payload?.data?.sponsor_name);
      }
    });
  }, []);

  return (
    <>
      <div className="modal fade m-0 p-0" id={`event_detail_moda`} tabIndex={-1} aria-labelledby={`event_detail_moda`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-black font-didot-bold" id="exampleModalToggleLabel">
                Event Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  toggleModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <h5>EXCLUSIVE GUEST LIST</h5>
              <p>
                You have received an exclusive invite by our sponsor, <strong>{sponsorName}</strong>. Please fill out your information in the provided
                form to confirm your identity and receive your complimentary ticket.
              </p>
              <iframe
                src={settings?.promo_url?.replaceAll("shorts", "embed")}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                width="100%"
                height="320"
              ></iframe>
              <div className="row">
                <div className="col-6 d-flex flex-column text-start my-4">
                  <h4 className="font-didot-bold">Date</h4>
                  <h6 className="font-didot">
                    OCTOBER 25th, 2024
                    <br />
                    7:00pm - 10:00pm
                  </h6>
                </div>
                <div className="col-6 d-flex flex-column text-start my-4">
                  <h4 className="font-didot-bold">Location</h4>
                  <h6 className="font-didot">First Unitarian Congregational Society in Brooklyn</h6>
                  <h6 className="font-didot">119-121 Pierrepont Street</h6>
                  <h6 className="font-didot">Brooklyn, NY 11201</h6>
                </div>
              </div>
              <div className="mb-5 text-start">
                <p className="m-0 font-graebenbach-bold fw-bold">Event Description</p>
                <p className="mb-3 font-graebenbach">
                  A ONCE in a lifetime NYC event that will make meeting humans face to face more worth your while than your favorite night in of
                  Netflix and Door Dash. <br />
                  <br />
                  And the real answers to science, religion, and aliens.
                  <br />
                  <br />
                  Not something you can find in an episode of Ancient Aliens. <br />
                  <br />
                  So what’s all the hype? What is in this full immersive experience?
                  <br />
                  <br />
                  EveryTHING and everyONE, including yourself, is curated. <br />
                  <br />
                  You get a night of live music, dance, theatrical performances. <br />
                  <br />
                  You get fed a delicious meal. <br />
                  <br />
                  We have a fashion show. <br />
                  <br />
                  And the people you will meet. No more Tinder, no more Ziprecruiter. We attract souls seeking the sacred arts all in one place.{" "}
                  <br />
                  <br />
                  So whatever you are looking for. Whoever you are looking for. It’s here. <br />
                  <br />
                  Take the introspective dive into the philosophy.
                  <br />
                </p>
              </div>
              <div className="mb-5 text-start">
                <p className="m-0 font-graebenbach-bold fw-bold">ZERO Philosophy</p>
                <p className="mb-3 font-graebenbach">
                  What if we embarked on a journey of contemplation that transcends the boundaries between belief and disbelief, seeking a shared
                  understanding that unites us all? Imagine exploring narratives from both religious and non-religious perspectives, striving to find
                  a definition of the divine that resonates universally.
                  <br />
                  <br />
                  Let’s consider the stories that have shaped civilizations: the miraculous births, the parting of seas, the conversations between
                  historical figures, and the celestial dreams. Could these stories hold truths that are both literal and metaphorical, appealing to
                  believers and non-believers alike? What if the essence of revelation lay not solely in the details, but in the underlying messages
                  that transcend religious boundaries?
                  <br />
                  <br />
                  Contemplate the concept that heaven and hell could represent our inner states, not just physical destinations. Could these realms
                  symbolize the consequences of our choices and actions, regardless of one’s theological stance? And what if the scriptures, cherished
                  by believers, were influenced by the gradual evolution of interpretations across generations, blending human understanding with
                  divine wisdom?
                  <br />
                  <br />
                  In a world where perspectives diverge, the art of discernment becomes vital. Balancing intuition and intellect, believers and
                  non-believers alike seek answers that resonate with their inner truth. Some draw comfort from circular philosophies, while others
                  contemplate moral hierarchies or personal purpose.
                  <br />
                  <br />
                  In the midst of a world facing moral complexities and competing priorities, the quest for spiritual truth often takes a back seat.
                  Leaders may prioritize power over compassion, and societies may prioritize correctness over empathy. So let’s ask ourselves, what if
                  the pursuit of knowledge led us to humility instead of arrogance? Could we envision a collective pursuit of love for all and
                  animosity for none, uniting humanity in a shared aspiration?
                  <br />
                  <br />
                  Amid the diverse tapestry of beliefs, let’s embark on this shared exploration, inviting dialogue that transcends divisions and
                  fosters understanding. In this space of unity, we seek a definition of the divine that bridges the gap between believers and
                  non-believers, creating a universal platform for reflection and connection.
                  <br />
                </p>
              </div>
              <div className="mb-5 text-start">
                <p className="m-0 font-graebenbach-bold fw-bold">Vision and Values</p>
                <p className="mb-3 font-graebenbach">
                  To restore the sanctity of art and to serve humanity by bringing together like-minded creatives of the world. At HXS, we believe in
                  the following values:
                  <br />
                  <br />
                  Context of speech.
                  <br />
                  Actions speak louder than likes.
                  <br />
                  Reward originality.
                  <br />
                  Social welfare all our shows are associated with a welfare cause.
                  <br />
                </p>
              </div>
              <div className="mb-5 text-start">
                <p className="m-0 font-graebenbach-bold fw-bold">HXS House Rules</p>
                <p className="mb-3 font-graebenbach">
                  No vulgarity, profanity, or intoxicants will be allowed on the show <br />
                  Observe silence during performances <br />
                  All tickets purchased for HXS events are final. We do not issue refunds or exchanges unless the show is cancelled. <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
