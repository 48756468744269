import { Project } from "..";
import PortfolioProjectsBaseLayout from "../../../layouts/PortfolioProjectsBaseLayout";

export interface PlatformProjectProps {
  project: Project;
}

interface ProjectDetailCardProps extends Project {
  images?: Record<string, any>[];
}

const ProjectDetailCard = (project: ProjectDetailCardProps) => {
  return (
    <div className="d-flex flex-column gap-2 center text-center">
      <div>
        <h5 className="font-didot-bold m-0">Project Type</h5>
        <p className="font-graebenbach">{project.project_type}</p>
      </div>
      {project?.collaboration && (
        <div>
          <h5 className="font-didot-bold m-0">Collaboration</h5>
          <p className="font-graebenbach">{project?.collaboration}</p>
        </div>
      )}
      <div>
        <h5 className="font-didot fw-bold m-0">Location</h5>
        <p className="font-graebenbach">{project.location}</p>
      </div>
      <a href={project.redirect_url} target="_blank" rel="noopener noreferrer">
        {project.thumbnails?.map((p, index) => {
          return (
            <div key={index} className="my-5">
              <img className="img-fluid" src={p.image || "/placeholder-vertical.jpg"} alt="thumbnail" />
            </div>
          );
        })}
      </a>
    </div>
  );
};

export default function PlatformProjectImages({ project }: PlatformProjectProps) {
  // const [currentOpenedIndex, setCurrentOpenedIndex] = React.useState(0);
  // const [isEnd, setIsEnd] = React.useState(false);

  // const handleNext = () => {
  //   if (project.thumbnails && currentOpenedIndex + 1 < project.thumbnails.length) {
  //     // Going to next step is only allowed if we are not in the last step
  //     setCurrentOpenedIndex((prev) => prev + 1);
  //   } else {
  //     // If we are after the last step, we need to make sure that we set isEnd true
  //     setIsEnd(true);
  //   }
  // };

  // const handlePrevious = () => {
  //   if (project.thumbnails && currentOpenedIndex <= project.thumbnails.length - 1) {
  //     // If we are on the second step, we need to make sure that before decreasing the step we have to set isEnd false
  //     setIsEnd(false);
  //   }
  //   if (currentOpenedIndex !== 0) {
  //     // Going to previous step is only allowed if we are not in the first step
  //     setCurrentOpenedIndex((prev) => prev - 1);
  //   }
  // };

  return (
    <PortfolioProjectsBaseLayout handleNext={() => {}} handlePrevious={() => {}}>
      <ProjectDetailCard {...project} images={project.thumbnails} />
    </PortfolioProjectsBaseLayout>
  );
}
