import { Navigate } from "react-router-dom";

import AppBaseLayout from "../layouts/AppBaseLayout";
import { useAppSelector } from "../store/hooks";
import { routes } from "./index";

export interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  if (!isAuthenticated) return <Navigate to={routes.login.path} />;
  return <AppBaseLayout>{children}</AppBaseLayout>;
};

export default PrivateRoute;
