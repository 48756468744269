import { Link, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { useAppSelector } from "../store/hooks";
import styles from "../styles/Footer.module.scss";
interface SocialLink {
  id: number;
  title: string;
  url: string;
  order: number;
}

export default function Footer() {
  const location = useLocation();
  const { settings } = useAppSelector((state) => state.settings);

  return (
    <div
      className={`d-flex gap-2 flex-md-row gap-md-5 mt-5 mb-3 ${styles["social-media-links"]}`}
      // style={{ position: "absolute", bottom: 0, margin: "auto", width: "100%" }}
      style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { color: "white" } : {}}
    >
      {settings?.social_links?.map((item: SocialLink) => {
        return (
          <div className="center d-flex gap-1" key={item.id}>
            <div
              className="dot"
              style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { background: "white" } : {}}
            ></div>
            <a href={item.url} className="text-capitalize" target="_blank" rel="noreferrer">
              <p className="m-0">{item.title}</p>
            </a>
          </div>
        );
      })}
      {/* <div className="center d-flex gap-1">
        <div
          className="dot"
          style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { background: "white" } : {}}
        ></div>
        <Link to={routes.contact.path}>Contact</Link>
      </div> */}
    </div>
  );
}
