import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI, postAPI } from "../../axios/utils";
import {
  PAYMENT_BILLING_INFO_SAVE_API,
  PAYMENT_CAPTURE_STRIPE_PAYMENT_API,
  PAYMENT_CREATE_STRIPE_INTENT_API,
  PAYMENT_HISTORY_API,
} from "../../utils/constants";

export const savePaymentBillingInfo = createAsyncThunk("payments/savePaymentBillingInfo", async (data: Record<string, any>) => {
  return postAPI(PAYMENT_BILLING_INFO_SAVE_API, data);
});

export const createStripePaymentIntent = createAsyncThunk("payments/createStripePaymentIntent", async (data: Record<string, any>) => {
  return postAPI(PAYMENT_CREATE_STRIPE_INTENT_API, data);
});

export const captureStripePayment = createAsyncThunk("payments/captureStripePayment", async (data: Record<string, any>) => {
  return postAPI(PAYMENT_CAPTURE_STRIPE_PAYMENT_API, data);
});

export const getTicketPurchaseHistory = createAsyncThunk("payments/getTicketPurchaseHistory", async () => {
  return getAPI(PAYMENT_HISTORY_API);
});
