import { useSearchParams } from "react-router-dom";
import sideMenuStyle from "../../styles/SideMenuSelector.module.scss";
import PaymentHistorySection from "./PaymentHistorySection";
import SideMenuSelector from "./components/SideMenuSelector";

const sideSelectorMenuItems = [
  { label: "Payment History", slug: "payment-history" },
//   { label: "Profile", slug: "profile" },
];

export default function AccountPage() {
  const [searchParams] = useSearchParams();

  const renderSection = (sectionSlug: string) => {
    switch (sectionSlug) {
      case sideSelectorMenuItems[0].slug:
        return <PaymentHistorySection />;
      case sideSelectorMenuItems[1].slug:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className="container-fluid pt-4 pb-5 px-0 px-md-auto">
      <div className="row position-relative">
        <div className={`col-0 col-md-3 mb-auto mb-md-auto ${sideMenuStyle["side-menu-selector"]}`}>
          <SideMenuSelector menuItems={sideSelectorMenuItems} />
        </div>
        <div className={"col-12 col-md-9"}>
          <div className="row">{renderSection(searchParams.get("section") || sideSelectorMenuItems[0].slug)}</div>
        </div>
      </div>
    </div>
  );
}
