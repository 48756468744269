import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { PLATFORM_TYPE } from "../../utils/constants";
import { getQuestionnaireQuestions, getQuestionnaireResponse, submitQuestionnaireResponse, updatedQuestionnaireResponse } from "./questionnaireThunk";

export interface QuestionnaireInitialStateProps {
  isLoading: boolean;
  questionnaire: Record<string, any>[];
  userResponses: Record<string, any>[];
  currentQuestionIndex: number;
  isEnd: boolean;
  currentPlatformName: string;
}

const QuestionnaireInitialState: QuestionnaireInitialStateProps = {
  isLoading: false,
  questionnaire: [],
  userResponses: [],
  currentQuestionIndex: 0,
  isEnd: false,
  currentPlatformName: PLATFORM_TYPE["DREAM_HUB"],
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: QuestionnaireInitialState,
  reducers: {
    nextQuestion: (state) => {
      if (state.currentQuestionIndex + 1 < state.questionnaire.length) {
        if (state.userResponses[state.currentQuestionIndex] === null || state.userResponses[state.currentQuestionIndex] === undefined) {
          // console.log("nothing is good");
          // toast.warning("Please answer this question.");
          if (state.questionnaire[state.currentQuestionIndex].is_required && state.userResponses[state.currentQuestionIndex] === null) {
            // console.log(state.questionnaire[state.currentQuestionIndex].is_required);
            // console.log("Stopping here.");
            toast.warning("Please answer this question.");
          } else {
            // console.log("Moving to next step");
            state.currentQuestionIndex += 1;
          }
        } else {
          // Going to next step is only allowed if we are not in the last step
          state.currentQuestionIndex += 1;
        }
      }
      // else if (state.questionnaire.length === state.userResponses.length) {
      // const tempUr: any = state.userResponses;
      // console.log(tempUr);
      // if (!tempUr.includes(null)) {
      //   // If we are after the last step, we need to make sure that we set isEnd true
      //   state.isEnd = true;
      // }
      // }
    },
    previousQuestion: (state) => {
      // if (state.currentQuestionIndex <= state.questionnaire.length - 1) {
      //   // If we are on the second step, we need to make sure that before decreasing the step we have to set isEnd false
      //   state.isEnd = false;
      // }
      if (state.currentQuestionIndex !== 0) {
        // Going to previous step is only allowed if we are not in the first step
        state.currentQuestionIndex -= 1;
      }
    },
    reset: (state) => {
      state.currentQuestionIndex = 0;
      state.isEnd = false;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    filterOutAnsweredQuestion: (state) => {
      const userResponsesIds = state.userResponses.map((q) => q.question);
      state.questionnaire = state.questionnaire.filter((q) => !userResponsesIds.includes(q.id));
    },
    addUserResponse: (state, action) => {
      state.userResponses[state.currentQuestionIndex] = { ...state.userResponses[state.currentQuestionIndex], ...action.payload };
    },
    changePlatform: (state, action) => {
      state.currentPlatformName = action.payload;
    },
  },
  extraReducers: (builder) => {
    // For Getting questionnaire questions
    builder
      .addCase(getQuestionnaireQuestions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQuestionnaireQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionnaire = action.payload.data;
        if (state.userResponses.length === 0) {
          state.userResponses = Array(action.payload.data.length);
        }
      })
      .addCase(getQuestionnaireQuestions.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For submitting questionnaire responses
    builder
      .addCase(submitQuestionnaireResponse.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitQuestionnaireResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionnaire[state.currentQuestionIndex].is_answered = true;
        state.userResponses[state.currentQuestionIndex] = action.payload.data;
        const tempUr: any = state.userResponses;
        const lastIndex = state.currentQuestionIndex === state.questionnaire.length - 1;
        if (lastIndex && (![...tempUr].includes(null) || ![...tempUr].includes(undefined))) {
          // console.log("Saving...", [...tempUr]);
          // If we are after the last step, we need to make sure that we set isEnd true
          state.isEnd = true;
        }
      })
      .addCase(submitQuestionnaireResponse.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For updating questionnaire responses
    builder
      .addCase(updatedQuestionnaireResponse.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatedQuestionnaireResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionnaire[state.currentQuestionIndex].is_answered = true;
        state.userResponses[state.currentQuestionIndex] = action.payload.data;
        const tempUr: any = state.userResponses;
        const lastIndex = state.currentQuestionIndex === state.questionnaire.length - 1;
        // console.log(![...tempUr].includes(null));
        // console.log(![...tempUr].includes(undefined));
        if (lastIndex && !([...tempUr].includes(null) || [...tempUr].includes(undefined))) {
          // console.log("Updating...", [...tempUr]);
          // If we are after the last step, we need to make sure that we set isEnd true
          state.isEnd = true;
        }
      })
      .addCase(updatedQuestionnaireResponse.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For fetching questionnaire responses
    builder
      .addCase(getQuestionnaireResponse.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQuestionnaireResponse.fulfilled, (state, action) => {
        state.userResponses = Array(state.questionnaire.length);
        action.payload.data?.forEach((r: { question: any }) => {
          const responsePossibleIndex = state.questionnaire.findIndex((q) => q.id === r.question);
          state.userResponses[responsePossibleIndex] = r;
        });
        state.isLoading = false;
      })
      .addCase(getQuestionnaireResponse.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const questionnaireActions = questionnaireSlice.actions;

export default questionnaireSlice;
