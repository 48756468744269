import React from "react";
import styles from "../../styles/MultiSelectAnswer.module.scss";

export interface MultiSelectAnswerProps {
  choices: Record<string, any>[];
  getAnswer: (value: number[]) => any;
  userAnswers: number[] | null;
}

export default function MultiSelectAnswer({ choices, getAnswer, userAnswers }: MultiSelectAnswerProps) {
  const [selectedAnswers, setSelectedAnswers] = React.useState<number[]>(userAnswers || []);
  const [isImageMultiSelectAnswer, setIsImageMultiSelectAnswer] = React.useState(Boolean(choices.find((c) => c.image)));

  React.useEffect(() => {
    getAnswer(selectedAnswers);
  }, [selectedAnswers]);

  React.useEffect(() => {
    const tempFlag = Boolean(choices.find((c) => c.image));
    setIsImageMultiSelectAnswer(tempFlag);
  }, [choices]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAnswers((prev) => {
      if (!e.target.checked) {
        return prev.filter((v) => v !== parseInt(e.target.value));
      } else {
        return [...prev, parseInt(e.target.value)];
      }
    });
  };

  return (
    <div className="container-fluid">
      <div
        className={`${styles["multi-select-buttons-group"]}  justify-content-center  ${
          isImageMultiSelectAnswer ? "row align-items-start" : "row flex-column align-items-center"
        }`}
      >
        {choices.map((choice, index) => {
          return (
            <div
              className={`${styles["select-button"]} ${
                isImageMultiSelectAnswer ? "mb-5 mb-md-5 col-12 col-sm-6 col-lg-3" : "max-auto mb-3 mb-md-3 col-12 col-sm-6 col-lg-4"
              } `}
              key={index}
            >
              {isImageMultiSelectAnswer && (
                <div className="text-center mb-3">
                  <img src={choice.image || "/placeholder-vertical.jpg"} alt="choice" style={{ objectFit: "cover" }} />
                </div>
              )}
              <input
                onChange={handleInputChange}
                type="checkbox"
                checked={selectedAnswers.includes(parseInt(choice.id))}
                value={choice.id}
                id={`choice-${choice.id}`}
              />
              <label className={`${styles["button-select"]}  px-5`} htmlFor={`choice-${choice.id}`}>
                {choice.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
