import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { forgotPasswordConfirm } from "../../store/auth/authThunk";
import { useAppDispatch } from "../../store/hooks";
import styles from "../../styles/LoginPage.module.scss";

const initialFormData = {
  password: "",
  confirmPassword: "",
};

export default function SetPassword() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const handleRouteClick = useHandleRouteClick();
  const token = params?.token;
  const uid = params?.uid;
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (!formData.password && !formData.confirmPassword) {
      toast.error("Both the fields are empty.");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error("Both passwords must match.");
      return;
    }
    dispatch(
      forgotPasswordConfirm({
        uid: uid,
        token: token,
        new_password: formData.confirmPassword,
      })
    )
      .then((res) => {
        if (res?.payload?.status === 204) {
          toast.success("Successfully changed your password, now you can login..");
          handleRouteClick(routes.login.path);
        } else {
          toast.error(res.payload.error.data.detail);
        }
        setFormData(initialFormData);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! seems like your link is invalid to reset the password.");
      });
  };

  return (
    <div className={`${styles.form} my-5 my-md-1`}>
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "78vh" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <div className="container my-2 py-4">
            <div className="row">
              <div className="col-12">
                <input
                  value={formData.password}
                  onChange={handleInputChange}
                  name="password"
                  required
                  type="password"
                  placeholder="Password"
                  className="my-2"
                />
              </div>
              <div className="col-12">
                <input
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  name="confirmPassword"
                  required
                  type="password"
                  placeholder="Confirm Password"
                  className="my-2"
                />
              </div>
            </div>
            <button className="my-3 border-0 bg-transparent" type="submit">
              <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
