import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { captureStripePayment } from "../../../store/payments/paymentsThunk";
import styles from "../../../styles/TicketBillingInfo.module.scss";
import { STRIPE_PUBLISHABLE_KEY } from "../../../utils/constants";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

interface StripeTicketFormProps {
  handleNextStep: () => void;
}

export default function StripeTicketForm({ handleNextStep }: StripeTicketFormProps) {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY || "");
  const [searchParams] = useSearchParams();

  const options = {
    // passing the client secret obtained from the server
    clientSecret: searchParams.get("client_secret") || "",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm handleSubmit={handleNextStep} />
    </Elements>
  );
}

interface CheckoutFormProps {
  handleSubmit: () => void;
}

const CheckoutForm = ({ handleSubmit: formHandleSubmit }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.tickets);
  const [searchParams] = useSearchParams();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/livestream/purchase/ticket?step=2`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (searchParams.get("client_secret")) {
        dispatch(captureStripePayment({ payment_intent_id: searchParams.get("payment_intent_client_secret") })).then((res) => {
          if (res?.payload?.status === 200 && res?.payload?.data?.response_data.status === "succeeded") {
            toast.success("Payment Successfully Transacted.");
            formHandleSubmit();
          } else {
            toast.error("Something went wrong wiht the payment.");
          }
        });
      }
    }
  };

  return (
    <div className={`text-center`}>
      <h4 className="font-didot-bold mb-4">Checkout</h4>
      <p className="font-graebenbach mb-4">
        {searchParams.get("ticket_count")} x Ticket Subtotal - $
        {settings.live_stream_ticket_price * parseInt(searchParams.get("ticket_count") || "0") - parseInt(searchParams.get("discount") || "0")}
      </p>
      <div>
        <button className="my-1 border-0 bg-transparent" type="button" onClick={() => {}}>
          <img src="/arrow.svg" alt="arrow" style={{ height: "1.5rem", transform: "rotate(180deg)" }} />
        </button>
      </div>
      <div
        style={{
          //   height: "62vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={`mb-4 ${styles.form}`}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            <div className="container px-0 my-2 py-4">
              <div className="row">
                <div className="col-12 my-3">
                  <PaymentElement />
                </div>
              </div>
              <button disabled={!stripe} type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-5">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
