import React from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import BGVideo from "../../components/BGVideo";
import { routes } from "../../routes";
import { login } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import styles from "../../styles/LoginPage.module.scss";

const initialFormData = {
  username: "",
  password: "",
};

export default function LogInPage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogIn = () => {
    if (formData.username && formData.password) {
      dispatch(login({ ...formData, username: formData.username.toLowerCase() }))
        .then((res) => {
          // console.log(res);
          if (res?.payload?.status !== 200) {
            toast.error(res.payload.error.data.detail);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Oops! Some issues while submitting.");
        });
    } else {
      toast.error("Username & Password both are required.");
    }
  };

  if (isAuthenticated) {
    return <Navigate to={routes.home.path} />;
  }

  return (
    <div className="d-flex flex-column justify-content-evenly">
      <BGVideo />
      <div className="brand-logo d-none flex-column d-md-block my-5 py-5 py-md-0">
        <img src="/full-logo.webp" alt="logo" />
        {/* <h1 className="font-graebenbach text-white mt-4" style={{ fontSize: "3rem" }}>
          SEEKERS OF THE SACRED ARTS
        </h1> */}
      </div>
      <div className="brand-logo d-flex flex-column d-md-none my-4 py-4">
        <img src="/mobile-full-logo.webp" alt="logo" />
        {/* <h5 className="font-graebenbach fw-bold text-white mt-2" style={{ fontSize: "1.7rem" }}>
          SEEKERS OF THE SACRED ARTS
        </h5> */}
      </div>
      {/* <p className="brand-logo full-width-text d-none d-md-block">HIDDENXSOUL</p>
      <div className="mt-5 pb-4">
        <p className="brand-logo full-width-text d-block d-md-none">HIDDEN</p>
        <p className="brand-logo full-width-text d-block d-md-none">XSOUL</p>
      </div> */}
      <div className={`${styles.form} my-md-1`}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleLogIn();
          }}
        >
          <div className="d-flex flex-column gap-3 align-items-center">
            <input onChange={handleInputChange} name="username" required type="text" placeholder="Username" />
            <input onChange={handleInputChange} name="password" required type="password" placeholder="Password" />
          </div>
          <div className="d-flex flex-column">
            <button className="my-3 border-0 bg-transparent" type="submit">
              <img src="/arrow_white.svg" alt="arrow" style={{ height: "3rem", transform: "rotate(90deg)" }} />
            </button>
            <Link
              to={routes.forgotPassword.path}
              style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { color: "white" } : {}}
            >
              <p className="font-compagnon-light m-0">forgot username or password?</p>
            </Link>
            <Link
              to={routes.register.path}
              style={location.pathname === routes.startPage.path || location.pathname === routes.login.path ? { color: "white" } : {}}
            >
              <p className="font-compagnon-light m-0">Don’t have an account? Sign up</p>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
