import React from "react";
import styles from "../../styles/MultiSelectAnswer.module.scss";

export interface ColorsAnswerProps {
  choices: Record<string, any>[];
  getAnswer: (value: number[]) => any;
  userAnswers: [] | null;
}

export default function ColorsAnswer({ choices, getAnswer, userAnswers }: ColorsAnswerProps) {
  const [selectedAnswers, setSelectedAnswers] = React.useState<number[]>(userAnswers || []);

  React.useEffect(() => {
    getAnswer(selectedAnswers);
  }, [selectedAnswers]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAnswers((prev) => {
      if (!e.target.checked) {
        return prev.filter((v) => v !== parseInt(e.target.value));
      } else {
        return [...prev, parseInt(e.target.value)];
      }
    });
  };

  return (
    <div className={`${styles["multi-select-buttons-group"]} d-flex gap-5 flex-wrap center`}>
      {choices.map((choice, index) => {
        return (
          <div className={`${styles["select-button"]}`} key={index}>
            <div className="text-center mb-3">
              <img src={choice.image || "/placeholder-vertical.jpg"} alt="choice" style={{ objectFit: "cover" }} />
            </div>
            <input
              onChange={handleInputChange}
              type="checkbox"
              checked={selectedAnswers.includes(parseInt(choice.id))}
              value={choice.id}
              id={`choice-${choice.id}`}
            />

            <label className={`${styles["button-select"]}`} htmlFor={`choice-${choice.id}`}>
              {choice.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}
