import React from "react";
import styles from "../../styles/MultiSelectAnswer.module.scss";

export interface SingleSelectAnswerProps {
  choices: Record<string, any>[];
  getAnswer: (value: number[]) => any;
  userAnswers: number[] | null;
}

export default function SingleSelectAnswer({ choices, getAnswer, userAnswers }: SingleSelectAnswerProps) {
  const [selectedAnswers, setSelectedAnswers] = React.useState<number[]>(userAnswers || []);
  const [isImageSingleSelectAnswer, setIsImageSingleSelectAnswer] = React.useState(Boolean(choices.find((c) => c.image)));

  React.useEffect(() => {
    getAnswer(selectedAnswers);
  }, [selectedAnswers]);

  React.useEffect(() => {
    const tempFlag = Boolean(choices.find((c) => c.image));
    setIsImageSingleSelectAnswer(tempFlag);
  }, [choices]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAnswers([parseInt(e.target.value)]);
  };

  return (
    <div className={`${styles["multi-select-buttons-group"]}`}>
      {choices.map((choice, index) => {
        return (
          <div className={`${styles["select-button"]}`} key={index}>
            {isImageSingleSelectAnswer && (
              <div className="text-center mb-3">
                <img src={choice.image || "/placeholder-vertical.jpg"} alt="choice" />
              </div>
            )}
            <input
              onChange={handleInputChange}
              type="radio"
              checked={selectedAnswers.includes(parseInt(choice.id))}
              value={choice.id}
              id={`choice-${choice.id}`}
            />
            <label className={`${styles["button-select"]}  px-5`} htmlFor={`choice-${choice.id}`}>
              {choice.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}
