import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI, patchAPI, postAPI } from "../../axios/utils";
import {
  QUESTIONNAIRE_GET_QUESTIONS_API,
  QUESTIONNAIRE_GET_RESPONSES_API,
  QUESTIONNAIRE_RESPONSE_SUBMIT_API,
  QUESTIONNAIRE_RESPONSE_UPDATE_API,
} from "../../utils/constants";

export const submitQuestionnaireResponse = createAsyncThunk("questionnaire/submitQuestionnaireResponse", async (data: Record<string, any>) => {
  let config = {};
  let asFormData = false;
  if ("files" in data) {
    asFormData = true;
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
  return postAPI(QUESTIONNAIRE_RESPONSE_SUBMIT_API, data, config, asFormData);
});

export const updatedQuestionnaireResponse = createAsyncThunk("questionnaire/updatedQuestionnaireResponse", async (data_: Record<string, any>) => {
  // console.log(data_);
  const { id, data } = data_;

  let config = {};
  let asFormData = false;
  if ("files" in data) {
    asFormData = true;
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
  // console.log(id, data);
  return patchAPI(`${QUESTIONNAIRE_RESPONSE_UPDATE_API}${id}/`, data, config, asFormData);
});

export const getQuestionnaireQuestions = createAsyncThunk(
  "questionnaire/getQuestionnaireQuestions",
  async ({ queryParams }: { queryParams?: Record<string, any> }) => {
    return getAPI(QUESTIONNAIRE_GET_QUESTIONS_API, {
      params: queryParams,
    });
  }
);

export const getQuestionnaireResponse = createAsyncThunk(
  "questionnaire/getQuestionnaireResponse",
  async ({ queryParams }: { queryParams?: Record<string, any> }) => {
    return getAPI(QUESTIONNAIRE_GET_RESPONSES_API, {
      params: queryParams,
    });
  }
);
