interface TeamMemberDetailModalProps {
  open: boolean;
  toggleModal: () => void;
  data: Record<string, any>;
}

export default function TeamMemberDetailModal({ open, toggleModal, data }: TeamMemberDetailModalProps) {
  return (
    <>
      <div
        className="modal fade m-0 p-0"
        id={`team_member_detail_modal_${data?.id}`}
        tabIndex={-1}
        aria-labelledby={`team_member_detail_modal_${data?.id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-black font-didot-bold" id="exampleModalToggleLabel">
                {data?.name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  toggleModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
