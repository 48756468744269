import React from "react";
import { FileData } from "./answers/FilesSelectAnswer";

export interface FileUploadProps {
  getFile: (value: FileData) => void;
}

export default function FileUpload({ getFile }: FileUploadProps) {
  const [file, setFile] = React.useState<File | undefined>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(() => {
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (!file) return;
      const fileUrl = URL.createObjectURL(file);
      getFile({ fileObj: file, fileUrl: fileUrl });
      return file;
    });
  };

  return (
    <>
      <input
        style={{ display: "none" }}
        id="profilePicture"
        name="profilePicture"
        className="profilePicture"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="profilePicture">
        <div style={{ border: "1px solid", borderRadius: "20px" }} className="px-4">
          BROWSE OR DRAG AND DROP
        </div>
      </label>
    </>
  );
}
