const getLocalRefreshToken = () => {
  const token = JSON.parse(localStorage.getItem("token") || "{}");
  return token?.refresh;
};

const getLocalAccessToken = () => {
  const token = JSON.parse(localStorage.getItem("token") || "{}");
  return token?.access;
};

const updateLocalAccessToken = (tokenStr: string) => {
  let token = JSON.parse(localStorage.getItem("token") || "{}");
  token.access = tokenStr;
  localStorage.setItem("token", JSON.stringify(token));
};

const updateLocalRefreshToken = (tokenStr: string) => {
  let token = JSON.parse(localStorage.getItem("token") || "{}");
  token.refresh = tokenStr;
  localStorage.setItem("token", JSON.stringify(token));
};

const getToken = () => {
  return JSON.parse(localStorage.getItem("token") || "{}");
};

const setToken = (tokenObj: Record<string, string>) => {
  localStorage.setItem("token", JSON.stringify(tokenObj));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user") || "{}");
};

const setUser = (userObj: Record<string, any>) => {
  localStorage.setItem("user", JSON.stringify(userObj));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  getToken,
  setToken,
  getUser,
  setUser,
  removeUser,
  removeToken,
};

export default TokenService;
