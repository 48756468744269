import React from "react";
import { toast } from "react-toastify";
import TokenService from "../axios/tokenservice";
import ColorsAnswer from "../components/answers/ColorsAnswer";
import CurrencyAnswer from "../components/answers/CurrencyAnswer";
import FilesSelectAnswer, { FileData } from "../components/answers/FilesSelectAnswer";
import MultiSelectAnswer from "../components/answers/MultiSelectAnswer";
import MultiSelectOrderedAnswer from "../components/answers/MultiSelectOrderedAnswer";
import NumberAnswer from "../components/answers/NumberAnswer";
import SingleSelectAnswer from "../components/answers/SingleSelectAnswer";
import TextAnswer from "../components/answers/TextAnswer";
import QuestionnaireBaseLayout from "../layouts/QuestionnaireBaseLayout";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { questionnaireActions } from "../store/questionnaire/questionnaireSlice";
import { submitQuestionnaireResponse, updatedQuestionnaireResponse } from "../store/questionnaire/questionnaireThunk";
import { QUESTION_TYPE, QUESTION_TYPE_PROPS } from "../utils/constants";

export interface AnswerChoicesProps {
  questionType: QUESTION_TYPE_PROPS;
  choices: Record<string, any>[];
  userAnswers: [] | null;
  getAnswer: (value: string | number[]) => any;
  userTextAnswer: string;
  userFileAnswers: FileData[];
  getFiles: (value: FileData[]) => any;
}

export interface QuestionnaireProps {
  title: string;
  platformName: string;
}

const AnswerChoices = ({ questionType, choices, getAnswer, userAnswers, userTextAnswer, getFiles, userFileAnswers }: AnswerChoicesProps) => {
  switch (questionType) {
    case QUESTION_TYPE["MULTI_SELECT"]:
      return <MultiSelectAnswer key={userAnswers?.length} choices={choices} getAnswer={getAnswer} userAnswers={userAnswers} />;
    case QUESTION_TYPE["MULTI_SELECT_ORDERED"]:
      return <MultiSelectOrderedAnswer key={userAnswers?.length} choices={choices} getAnswer={getAnswer} userAnswers={userAnswers} />;
    case QUESTION_TYPE["SINGLE_SELECT"]:
      return <SingleSelectAnswer key={userAnswers?.length} choices={choices} getAnswer={getAnswer} userAnswers={userAnswers} />;
    case QUESTION_TYPE["TEXT"]:
      return <TextAnswer key={userTextAnswer} getAnswer={getAnswer} userAnswer={userTextAnswer} />;
    case QUESTION_TYPE["NUMBER"]:
      return <NumberAnswer key={userTextAnswer} getAnswer={getAnswer} userAnswer={userTextAnswer} />;
    case QUESTION_TYPE["CURRENCY"]:
      return <CurrencyAnswer key={userTextAnswer} getAnswer={getAnswer} userAnswer={userTextAnswer} />;
    case QUESTION_TYPE["FILES_UPLOAD"]:
      return <FilesSelectAnswer key={userFileAnswers?.length} getFiles={getFiles} userFileAnswers={userFileAnswers} />;
    case QUESTION_TYPE["COLORS"]:
      return <ColorsAnswer key={userAnswers?.length} choices={choices} getAnswer={getAnswer} userAnswers={userAnswers} />;
    default:
      return <TextAnswer key={userTextAnswer} getAnswer={getAnswer} userAnswer={userTextAnswer} />;
  }
};

export default function Questionnaire({ title, platformName }: QuestionnaireProps) {
  const dispatch = useAppDispatch();
  const [currentAnswer, setCurrentAnswer] = React.useState<number[]>([]);
  const [currentAnswerFiles, setCurrentAnswerFiles] = React.useState<FileData[]>([]);
  const [currentAnswerText, setCurrentAnswerText] = React.useState<string>();
  const { questionnaire, currentQuestionIndex, userResponses, isEnd } = useAppSelector((state) => state.questionnaire);
  const currentQuestion = questionnaire[currentQuestionIndex];
  const currentUserResponse = userResponses.find((ur) => ur?.question === currentQuestion?.id);

  console.log(
    currentAnswerFiles,
    currentAnswerFiles.map((f) => ({ file: f.fileObj, description: f.description }))
  );

  const handleSubmit = () => {
    if (isEnd) {
    } else {
      const data: Record<string, any> = {
        user: TokenService.getUser().id,
        question: questionnaire[currentQuestionIndex].id,
      };
      if (currentAnswerText) {
        data["answer_text"] = currentAnswerText;
      }
      if (currentAnswer.length > 0) {
        data["answer"] = currentAnswer;
      }
      if (currentAnswerFiles.length > 0) {
        // data["files"] = currentAnswerFiles.map((f) => ({ file: f.fileObj, description: f.description }));
        data["files"] = currentAnswerFiles.map((f) => {
          if ("id" in f) {
            return { id: f.id };
          } else {
            return { file: f.fileObj, description: f.description };
          }
        });
      }

      if (data["answer_text"] || data["answer"]?.length > 0 || data["files"]?.length > 0) {
        dispatch(questionnaireActions.addUserResponse(data));

        // Check if the question is already answered
        if (!questionnaire[currentQuestionIndex].is_answered) {
          dispatch(submitQuestionnaireResponse(data)).then((res) => {
            if (res.payload?.status === 201) {
              dispatch(questionnaireActions.nextQuestion());
              //   dispatch(questionnaireActions.filterOutAnsweredQuestion());
            } else {
              toast.error("Failed to submit the response.");
            }
          });
        } else {
          dispatch(updatedQuestionnaireResponse({ id: currentUserResponse?.id, data })).then((res) => {
            if (res.payload?.status === 200) {
              dispatch(questionnaireActions.nextQuestion());
              //   dispatch(questionnaireActions.filterOutAnsweredQuestion());
            } else {
              toast.error("Failed to update the response.");
            }
          });
        }
      } else {
        if (!questionnaire[currentQuestionIndex].is_required) {
          dispatch(questionnaireActions.nextQuestion());
        } else {
          toast.warning("Please answer the question.");
        }
      }
    }
  };

  const getAnswer = (answer: string | number[]) => {
    if (typeof answer === "string") {
      setCurrentAnswerText(answer);
      setCurrentAnswer([]);
      setCurrentAnswerFiles([]);
    } else if (answer instanceof Array) {
      setCurrentAnswerText("");
      setCurrentAnswer(answer);
      setCurrentAnswerFiles([]);
    } else {
      setCurrentAnswerText(answer);
      setCurrentAnswer([]);
      setCurrentAnswerFiles([]);
    }
  };

  const getFiles = (files: FileData[]) => {
    setCurrentAnswerFiles(files);
    setCurrentAnswer([]);
    setCurrentAnswerText("");
  };

  return (
    <QuestionnaireBaseLayout title={title} platformName={platformName}>
      {questionnaire.length > 0 ? (
        <div>
          <h5 className="font-didot fw-bold text-center">{questionnaire[currentQuestionIndex].question_text}</h5>
          <p className="text-center font-didot">{questionnaire[currentQuestionIndex].question_description}</p>
          <div className="d-flex justify-content-center mt-5 mb-4">
            <AnswerChoices
              key={currentQuestionIndex}
              questionType={questionnaire[currentQuestionIndex].question_type}
              choices={questionnaire[currentQuestionIndex].answers}
              userAnswers={currentQuestionIndex in userResponses ? currentUserResponse?.answer : null}
              userTextAnswer={currentQuestionIndex in userResponses ? currentUserResponse?.answer_text : null}
              getAnswer={getAnswer}
              getFiles={getFiles}
              userFileAnswers={currentQuestionIndex in userResponses ? currentUserResponse?.files : null}
            />
          </div>
          <div className="text-center">
            <button type="button" onClick={() => handleSubmit()} className="font-graebenbach submit-button px-4 my-5 my-md-3">
              SUBMIT
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="text-center">No Questions Found</div>
        </>
      )}
    </QuestionnaireBaseLayout>
  );
}
