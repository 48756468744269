import React from "react";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { validateTicketCouponCode } from "../../../store/tickets/ticketsThunk";
import styles from "../../../styles/TicketBillingInfo.module.scss";

interface TicketSelectionProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
  formData: Record<string, any>;
  initialFormData: Record<string, any>;
  setFormData: any;
  discount: number;
  setDiscount: any;
}

export default function TicketSelection({
  handleNextStep,
  isPartnerPortal = false,
  formData,
  initialFormData,
  setFormData,
  discount,
  setDiscount,
}: TicketSelectionProps) {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.tickets);
  const handleRouteClick = useHandleRouteClick();
  const [couponValidated, setCouponValidated] = React.useState(false);

  React.useEffect(() => {
    if (formData.coupon_code) {
      handleCouponValidity(formData.coupon_code);
    }
  }, [formData.ticket_count]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    if (parseInt(val) <= 0) {
      setFormData((prev: Record<string, any>) => ({
        ...prev,
        [e.target.name]: 1,
      }));
    } else {
      setFormData((prev: Record<string, any>) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleCouponValidity = (coupon: string) => {
    if (!coupon) return;
    dispatch(validateTicketCouponCode({ coupon: coupon, number_of_tickets: formData.ticket_count, ticket_amount: settings.ticket_price })).then(
      (res) => {
        if (res.payload?.status === 200) {
          setDiscount(res.payload.data.discount_amount);
          toast.success(`Hooray! You got a discount of $${res.payload.data.discount_amount}.`);
          setCouponValidated(true);
        } else {
          toast.warning(`Invalid Coupon!`);
          return;
        }
      }
    );
  };

  return (
    <div>
      {!isPartnerPortal && (
        <div className="my-3 d-flex justify-content-between">
          <div>
            <a href={routes.home.path}>
              <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                Home
              </h4>
            </a>
          </div>
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        </div>
      )}
      <div className={`text-center`}>
        <h4 className="font-didot-bold mb-4">Checkout</h4>
        <p className="font-graebenbach mb-4">
          {formData.ticket_count} x Ticket Subtotal - ${formData.ticket_count * settings.ticket_price - discount}
        </p>
        <div
          style={{
            height: "62vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={`mb-4 ${styles.form}`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleNextStep();
                // if (formData.ticket_count * settings.ticket_price - discount === 0) {
                // }
              }}
            >
              <div className="container px-0 my-2 py-4">
                <div className="row">
                  <div className="col-12 my-3">
                    <input
                      defaultValue={1}
                      value={formData.ticket_count}
                      onChange={handleInputChange}
                      name="ticket_count"
                      required
                      type="number"
                      min={1}
                      placeholder="Number of Tickets"
                    />
                  </div>
                  <div className="col-12 my-3">
                    <input
                      disabled={couponValidated}
                      className={`${styles["soft-input"]} ${couponValidated ? "validated-input" : ""}`}
                      value={formData.coupon_code}
                      onChange={handleInputChange}
                      name="coupon_code"
                      type="text"
                      placeholder="Promo Code (Optional)"
                    />
                  </div>
                </div>
                {!couponValidated && formData.coupon_code.length > 0 ? (
                  <button
                    onClick={() => handleCouponValidity(formData.coupon_code)}
                    type="button"
                    className="font-graebenbach submit-btn px-4 py-1 my-2 my-md-2"
                  >
                    APPLY PROMO
                  </button>
                ) : (
                  <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-2 my-md-2">
                    SUBMIT
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
