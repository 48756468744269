import React from "react";
import { routes } from "../routes";
import { useHandleRouteClick } from "../routes/hooks";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { questionnaireActions } from "../store/questionnaire/questionnaireSlice";
import { getQuestionnaireQuestions, getQuestionnaireResponse } from "../store/questionnaire/questionnaireThunk";
import styles from "../styles/QuestionnaireBaseLayout.module.scss";

export interface QuestionnaireBaseLayoutProps {
  children?: React.ReactNode;
  title: string;
  platformName: string;
}

export default function QuestionnaireBaseLayout({ children, title, platformName }: QuestionnaireBaseLayoutProps) {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const { isEnd, isLoading, currentPlatformName } = useAppSelector((state) => state.questionnaire);

  const handleNextQuestion = () => {
    dispatch(questionnaireActions.nextQuestion());
  };

  const handlePreviousQuestion = () => {
    dispatch(questionnaireActions.previousQuestion());
  };

  React.useEffect(() => {
    dispatch(getQuestionnaireQuestions({ queryParams: { platform_name: platformName } })).then(() => {
      dispatch(getQuestionnaireResponse({ queryParams: { platform_name: platformName } })).then(() => {
        dispatch(questionnaireActions.changePlatform(platformName));
      });
    });
  }, []);

  React.useEffect(() => {
    dispatch(questionnaireActions.reset());
  }, [currentPlatformName]);

  return (
    <>
      <div className={styles.questionnaire}>
        <div className="container" style={{ position: "relative" }}>
          <div className="row justify-content-between align-items-center pt-4 mt-md-auto px-auto px-md-5">
            <div className="w-100 d-flex justify-content-between">
              <h4
                className={`d-blocl d-md-none m-0 font-didot-bold`}
                onClick={() => handleRouteClick(routes.logout.path)}
                style={{ cursor: "pointer" }}
              >
                Log Out
              </h4>
              <h4
                className={`d-blocl d-md-none m-0 font-didot-bold`}
                onClick={() => handleRouteClick(routes.home.path)}
                style={{ cursor: "pointer" }}
              >
                Home
              </h4>
            </div>
            <div className="w-auto d-flex align-items-center gap-3">
              {/* {!isEnd && (
                <button className="my-3 border-0 bg-transparent w-auto" type="button" onClick={() => handleNextQuestion()}>
                  <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(90deg)" }} />
                </button>
              )} */}
              <h4
                className={`d-none d-md-block m-0 font-didot-bold ${styles["logout-btn"]}`}
                onClick={() => handleRouteClick(routes.logout.path)}
                style={{ cursor: "pointer" }}
              >
                Log Out
              </h4>
            </div>
            <p className="m-0 w-auto text-center font-graebenbach fw-bold">{title}</p>
            <div className="w-auto d-flex align-items-center gap-3">
              <h4
                className={`d-none d-md-block m-0 font-didot-bold ${styles["home-btn"]}`}
                onClick={() => handleRouteClick(routes.home.path)}
                style={{ cursor: "pointer" }}
              >
                Home
              </h4>
              {!isEnd && (
                <button className="my-3 me-3 border-0 bg-transparent w-auto" type="button" onClick={() => handlePreviousQuestion()}>
                  <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(-180deg)" }} />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="my-3" style={isEnd ? { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" } : {}}>
          {isEnd ? (
            <>
              <p className="text-center font-didot my-5">
                Thank you, our team will evaluate all your requirements and reach out to you with next step.
              </p>
            </>
          ) : (
            children
          )}
        </div>
      </div>
    </>
  );
}
