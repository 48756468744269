export default function NotFound() {
  return (
    <>
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div className="container">
          <a href="/" style={{ marginBottom: "20px" }}>
            Home
          </a>
          <div style={{ textAlign: "center" }}>
            <p>Page Not Found</p>
          </div>
        </div>
      </div>
    </>
  );
}
