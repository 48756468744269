import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTicketPurchaseHistory } from "../../store/payments/paymentsThunk";
import { convertDateTimeToDayMonthYear } from "../../utils/helper";

export default function PaymentHistorySection() {
  const dispatch = useAppDispatch();
  const { ticketPurchaseHistory } = useAppSelector((state) => state.payments);

  React.useEffect(() => {
    dispatch(getTicketPurchaseHistory());
  }, []);

  return (
    <div>
      <div className="row my-5 justify-content-center">
        <div className="col-12 table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Tickets</th>
                <th scope="col">Ammount</th>
                <th scope="col">Status</th>
                <th scope="col">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {ticketPurchaseHistory.map((v_: Record<string, any>, index: number) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{v_?.ticket_id_last_6_chars}</td>
                    <td>{v_.number_of_tickets}</td>
                    <td>{v_?.amount}</td>
                    <td>{v_.status}</td>
                    <td>{convertDateTimeToDayMonthYear(v_?.created_at)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
