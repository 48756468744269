import React, { forwardRef, HTMLAttributes } from "react";
import styles from "../../styles/MultiSelectOrderedAnswer.module.scss";
import { SortableList } from "../sortable/SortableList";

export interface MultiSelectOrderedAnswerProps {
  choices: Record<string, any>[];
  getAnswer: (value: number[]) => any;
  userAnswers: number[] | null;
}

function sortChoicesByOrder(arrayOfObjects: Record<string, any>[], orderArray: number[]): Record<string, any>[] {
  const sortedArray: Record<string, any>[] = [];

  orderArray.forEach((id) => {
    const foundObject = arrayOfObjects.find((obj) => String(obj.id) === String(id));
    if (foundObject) {
      sortedArray.push(foundObject);
    }
  });

  return sortedArray;
}

type ItemProps = {
  item: Record<string, any>;
  isOpacityEnabled?: boolean;
  isDragging?: boolean;
  isImage: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Item = forwardRef<HTMLDivElement, ItemProps>(({ item, isOpacityEnabled, isDragging, isImage, style, ...props }, ref) => {
  const [isImageLoadingError, setIsImageLoadingError] = React.useState(false);

  return (
    <div
      ref={ref}
      style={{
        opacity: isOpacityEnabled ? "0.4" : "1",
        cursor: isDragging ? "grabbing" : "grab",
        lineHeight: "0.5",
        transform: isDragging ? "scale(1.05)" : "scale(1)",
        ...style,
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        alignItems: "center",
        width: "100%",
      }}
      {...props}
    >
      {isImage && (
        <img
          onError={() => setIsImageLoadingError(true)}
          src={isImageLoadingError ? "/placeholder-vertical.jpg" : item.image || "/placeholder-vertical.jpg"}
          alt={`${item.id}`}
          style={{
            borderRadius: "8px",
            boxShadow: isDragging ? "none" : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
            maxWidth: "100%",
            width: "auto",
            objectFit: "cover",
          }}
        />
      )}
      <div className={`${styles["btn"]} px-3 py-1 w-100`}>{item.label}</div>
    </div>
  );
});

export default function MultiSelectOrderedAnswer({ choices, getAnswer, userAnswers }: MultiSelectOrderedAnswerProps) {
  const [items, setItems] = React.useState<Record<string, any>[]>(() => {
    if (userAnswers?.length === choices.length) {
      return sortChoicesByOrder(choices, userAnswers).filter(Boolean);
    } else {
      return choices;
    }
  });

  const [isImageMultiSelectAnswer, setIsImageMultiSelectAnswer] = React.useState(Boolean(choices.find((c) => c.image)));

  React.useEffect(() => {
    const tempFlag = Boolean(choices.find((c) => c.image));
    setIsImageMultiSelectAnswer(tempFlag);
  }, [choices]);

  return (
    <div className={`${styles["multi-select-buttons-group"]} col-xs-12`}>
      <SortableList
        layout={isImageMultiSelectAnswer ? "horizontal" : "vertical"}
        items={items.map((i) => ({
          id: i.id,
          ...i,
        }))}
        onChange={(value) => {
          getAnswer(value.map((v) => v.id));
          setItems(value);
        }}
        renderItem={(item) => (
          <SortableList.Item id={item.id}>
            <Item isImage={isImageMultiSelectAnswer} item={item} />
            <SortableList.DragHandle />
          </SortableList.Item>
        )}
      />
    </div>
  );
}
