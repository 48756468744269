import { Navigate } from "react-router-dom";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppSelector } from "../../store/hooks";
import styles from "../../styles/Home.module.scss";

export default function ServicesPage() {
  const handleRouteClick = useHandleRouteClick();
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} />;
  }

  return (
    <div className={styles.home}>
      <div className="mt-4 mb-4 d-flex justify-content-between">
        <div>
          <a href={routes.home.path}>
            <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
              Home
            </h4>
          </a>
        </div>
        <div>
          <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
            Log Out
          </h4>
        </div>
      </div>
      <div className={`${styles["our-services"]} mt-4 mb-5`}>
        <div className="d-flex flex-column justify-content-center text-center dream-hub my-3">
          <div className="my-1">
            <button className="my-3 px-4 font-graebenbach" type="button" onClick={() => handleRouteClick(routes.dreamHub.path)}>
              DREAM HUB
            </button>
          </div>
          <p className="font-didot fw-bold mx-auto">
            We work with you to create your dream space. Residential, Hospitality, Corporate, or Commercial. Architectural, Interior, and Development
            Services.
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center text-center xlab my-3">
          <div className="my-1">
            <button className="my-3 px-4 font-graebenbach" type="button" onClick={() => handleRouteClick(routes.xlab.path)}>
              XLAB
            </button>
          </div>
          <p className="font-didot fw-bold mx-auto">
            We take care of all your technological needs here. Graphic Design, app development, process documentation, conversational GPT, manual
            labor automation, digital workforce.
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center text-center xm bmy-3">
          <div className="my-1">
            <button className="my-3 px-4 font-graebenbach" type="button" onClick={() => handleRouteClick(routes.xm.path)}>
              XM
            </button>
          </div>
          <p className="font-didot fw-bold mx-auto">
            Elevate your brand with our Experiential Marketing Services, where we craft immersive and unforgettable experiences through our shows,
            interactive installations, and engaging campaigns, fostering meaningful connections between your brand and your audience.
          </p>
        </div>
      </div>
    </div>
  );
}
