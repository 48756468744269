import React from "react";
import { toast } from "react-toastify";
import CopyInput from "../../components/CopyInput";
import UiLoading from "../../layouts/UiLoading";
import { routes } from "../../routes";
import { getMyDetail } from "../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { confirmGuestTicket, inviteGuest } from "../../store/tickets/ticketsThunk";

const initialFormData = {
  email: "",
  sponsor_name: "",
  invitation_url: "",
};

export default function SponsorGuests() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.tickets);
  const [formData, setFormData] = React.useState(initialFormData);
  const sponsorCode = user?.sponsor_profile?.sponsor_code;
  const refUrl = `${window.location.origin}${routes.register.path}?ref_code=${sponsorCode}`;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleConfirmGuest = ({ ticket_id }: Record<string, any>) => {
    dispatch(confirmGuestTicket({ ticket_id })).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res.payload.data.detail);
        dispatch(getMyDetail());
      } else {
        toast.error("Oops! Something went wrong while confirming the guest.");
      }
    });
  };

  React.useEffect(() => {
    dispatch(getMyDetail());
  }, []);

  const handleSubmit = () => {
    if (formData.email) {
      dispatch(inviteGuest({ ...formData, sponsor_name: user?.full_name || user?.username || "Sponsor", invitation_url: refUrl })).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res.payload.data.detail);
        } else {
          toast.error("Oops! Something went wrong while inviting the guest.");
        }
      });
    } else {
      toast.error("Please write the email first.");
    }
  };

  return (
    <div className={`container-fluid`}>
      {isLoading && <UiLoading height="100vh" />}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-5">
          <CopyInput text={refUrl} successMessage="The link is copied successfully!" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-5">
          <p className="font-didot m-0 mt-3">Invite Guest</p>
          <div className="d-flex flex-row justi-content-center align-items-center gap-2">
            <input
              className="form-control shadow-none w-100"
              value={formData.email}
              onChange={handleInputChange}
              name="email"
              required
              type="email"
              placeholder="Email *"
            />
            <button type="button" onClick={() => handleSubmit()} className="font-graebenbach submit-button px-4 my-5 my-md-3 w-auto">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
      <div className="row my-5 justify-content-center">
        <div className="col-12 col-lg-5 table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Ticket ID</th>
                <th scope="col">Confirmation Status</th>
              </tr>
            </thead>
            <tbody>
              {user?.sponsor_profile?.guests?.map((v_: Record<string, any>, index: number) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{v_?.user__email}</td>
                    <td>{v_.ticket_id}</td>
                    <td>
                      {v_.is_confirmed ? (
                        "Confirmed"
                      ) : (
                        <>
                          <button onClick={() => handleConfirmGuest({ ticket_id: v_.ticket_id })} className="submit-button">
                            Confirm
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
