import { createSlice } from "@reduxjs/toolkit";

import {
  getAboutPageText,
  getGeneralInformation,
  getGlobalSettings,
  getSponsorDetailAPI,
  getTeamMembersDetailAPI,
  getTeamMembersListAPI,
} from "./settingsThunk";

export interface SettingsInitialStateProps {
  isLoading: boolean;
  settings: Record<string, any>;
  generalInformation: Record<string, any>;
  aboutPage: Record<string, any>;
}

const SettingsInitialState: SettingsInitialStateProps = {
  isLoading: false,
  settings: {},
  generalInformation: {},
  aboutPage: {},
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: SettingsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For getting settings
    builder
      .addCase(getGlobalSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGlobalSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload.data;
      })
      .addCase(getGlobalSettings.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For getting about page text
    builder
      .addCase(getAboutPageText.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAboutPageText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aboutPage = action.payload.data;
      })
      .addCase(getAboutPageText.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For getting general info
    builder
      .addCase(getGeneralInformation.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGeneralInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.generalInformation = action.payload.data;
      })
      .addCase(getGeneralInformation.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For getting team members list
    builder
      .addCase(getTeamMembersListAPI.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTeamMembersListAPI.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTeamMembersListAPI.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For getting team members detail
    builder
      .addCase(getTeamMembersDetailAPI.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTeamMembersDetailAPI.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getTeamMembersDetailAPI.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For getting sponsor detail
    builder
      .addCase(getSponsorDetailAPI.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSponsorDetailAPI.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSponsorDetailAPI.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;
