import React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { createStripePaymentIntent } from "../../../store/payments/paymentsThunk";
import TicketBillingInfo from "./TicketBillingInfo";
import TicketContactInfo from "./TicketContactInfo";
import TicketHome from "./TicketHome";
import TicketPaymentConfirmation from "./TicketPaymentConfirmation";
import TicketPurchaseDetail from "./TicketPurchaseDetail";
import TicketQuestionnaire from "./TicketQuestionnaire";
import TicketSelection from "./TicketSelection";
import TicketStripePayment from "./TicketStripePayment";

interface FormStepInterface {
  id: string;
  component: React.ReactElement;
}

interface TicketInterface {
  isPartnerPortal?: boolean;
}

const initialFormData = {
  ticket_count: 1,
  coupon_code: "",
};

export default function Ticket({ isPartnerPortal = false }: TicketInterface) {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const { settings } = useAppSelector((state) => state.tickets);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = React.useState<string>(searchParams.get("step") || "");
  const [formData, setFormData] = React.useState(initialFormData);
  const [discount, setDiscount] = React.useState(0);

  const handleNextStep = (currentStep_: string, formSteps_: FormStepInterface[], isLiveStream = false) => {
    setSearchParams((params) => {
      const currentIndex_ = formSteps_.findIndex((s) => s.id === currentStep_);
      switch (isLiveStream) {
        case true:
          if (isPartnerPortal) {
            params.set("step", "ticket_contact_info");
            setCurrentStep("ticket_contact_info");
          } else {
            params.set("step", "ticket_stripe_payment");
            setCurrentStep("ticket_stripe_payment");
          }
          params.set("isLiveStream", "true");
          break;
        default:
          params.set("step", formSteps_[currentIndex_ + 1].id);
          setCurrentStep(formSteps_[currentIndex_ + 1].id);
          break;
      }

      return params;
    });
  };

  const handleCreateLiveStreamPaymentIntent = () => {
    dispatch(
      createStripePaymentIntent({
        amount: settings.live_stream_ticket_price,
        number_of_tickets: 1,
        ticket_type: "LIVE_STREAM",
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          handleRouteClick(`${routes.livestreamTicket.path}?client_secret=${res.payload.data.client_secret}&ticket_count=1`);
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  const handleCreatePaymentIntent = () => {
    if (formData.ticket_count <= 0) {
      toast.error("You need to select atleast 1 ticket.");
      return;
    }
    dispatch(
      createStripePaymentIntent({
        amount: settings.ticket_price,
        number_of_tickets: formData.ticket_count,
        coupon: formData.coupon_code,
        ticket_type: "IN_PERSON",
      })
    )
      .then((res) => {
        if (res?.payload?.status === 200) {
          // toast.success("Successfully submitted.");
          if (res.payload.data.is_free_ticket) {
            handleNextStep(currentStep, formSteps);
          }
          setFormData(initialFormData); // Resetting the form
          setSearchParams((prev) => {
            const prevParams: Record<string, any> = {};
            prev.forEach((value, key) => {
              prevParams[key] = value;
            });
            return {
              ...prevParams,
              client_secret: res.payload.data.client_secret,
              ticket_count: String(formData.ticket_count),
              discount: String(discount),
            };
          });
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  const formSteps: FormStepInterface[] = [
    // Conditionally add the contact step based on the if it is prtner poral
    ...(!isPartnerPortal
      ? [
          {
            id: "event_detail",
            component: <TicketHome handleNextStep={() => handleNextStep(currentStep, formSteps)} isPartnerPortal={isPartnerPortal} />,
          },
        ]
      : []),

    {
      id: "ticket_purchase_detail",
      component: (
        <TicketPurchaseDetail
          handleNextStep={() => handleNextStep(currentStep, formSteps)}
          handleLiveStreamNextStep={() => handleNextStep(currentStep, formSteps, true)}
          isPartnerPortal={isPartnerPortal}
        />
      ),
    },

    // Conditionally add the questionnaire step based on the if it is prtner poral
    ...(!isPartnerPortal
      ? [
          {
            id: "ticket_questionnaire",
            component: <TicketQuestionnaire handleNextStep={() => handleNextStep(currentStep, formSteps)} isPartnerPortal={isPartnerPortal} />,
          },
        ]
      : []),
    {
      id: "ticket_selection",
      component: (
        <TicketSelection
          handleNextStep={() => handleNextStep(currentStep, formSteps)}
          isPartnerPortal={isPartnerPortal}
          formData={formData}
          setFormData={setFormData}
          discount={discount}
          setDiscount={setDiscount}
          initialFormData={initialFormData}
        />
      ),
    },
    // Conditionally add the contact step based on the if it is prtner poral
    ...(isPartnerPortal
      ? [
          {
            id: "ticket_contact_info",
            component: <TicketContactInfo handleNextStep={() => handleNextStep(currentStep, formSteps)} isPartnerPortal={isPartnerPortal} />,
          },
        ]
      : []),
    {
      id: "ticket_billing_info",
      component: <TicketBillingInfo handleNextStep={() => handleNextStep(currentStep, formSteps)} isPartnerPortal={isPartnerPortal} />,
    },
    {
      id: "ticket_stripe_payment",
      component: <TicketStripePayment handleNextStep={() => handleNextStep(currentStep, formSteps)} isPartnerPortal={isPartnerPortal} />,
    },
    {
      id: "ticket_confirmation",
      component: <TicketPaymentConfirmation handleNextStep={() => handleRouteClick(routes.startPage.path)} isPartnerPortal={isPartnerPortal} />,
    },
  ];

  const currentIsLastStep = formSteps[formSteps.length - 1].id === currentStep;
  const currentIsFirstStep = formSteps[0].id === currentStep;

  const renderFormStep = () => {
    return formSteps.find((s) => s.id === currentStep)?.component;
  };

  React.useEffect(() => {
    if (!currentStep) {
      setSearchParams((params) => {
        params.set("step", formSteps[0].id);
        setCurrentStep(formSteps[0].id);
        return params;
      });
    }
  }, []);

  React.useEffect(() => {
    if (currentStep === "ticket_stripe_payment") {
      console.log("isLiveStream", searchParams.get("isLiveStream"));
      switch (searchParams.get("isLiveStream")) {
        case "true":
          handleCreateLiveStreamPaymentIntent();
          break;
        default:
          handleCreatePaymentIntent();
          break;
      }
    }
  }, [currentStep]);

  return (
    <div>
      <div>{renderFormStep()}</div>
    </div>
  );
}
