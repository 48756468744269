import { useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface Platform {
  label: string;
  slug: string;
}

export interface SidePlatformSelectorProps {
  platforms: Array<Platform>;
}

export default function SidePlatformSelector({ platforms }: SidePlatformSelectorProps) {
  const handleRouteClick = useHandleRouteClick();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      {platforms.map((p, index) => {
        return (
          <p
            key={index}
            className="font-graebenbach m-0"
            style={{ cursor: "pointer", fontStyle: p.slug === searchParams.get("platformName") ? "italic" : "unset" }}
            onClick={() => {
              handleRouteClick(`${routes.portfolio.path}?platformName=${p.slug}`);
              // setSearchParams({ platformName: p.slug });
            }}
          >
            {p.label}
          </p>
        );
      })}
      <p
        className="font-graebenbach m-0"
        style={{ cursor: "pointer", fontStyle: "all" === searchParams.get("platformName") ? "italic" : "unset" }}
        onClick={() => {
          handleRouteClick(`${routes.portfolio.path}?platformName=${"all"}`);
          // setSearchParams({ platformName: "all" });
        }}
      >
        All Project Types
      </p>
    </div>
  );
}
