import React from "react";
import TeamMemberDetailModal from "./TeamMemberDetailModal";

interface MemberItemProps {
  data: Record<string, any>;
}

export default function MemberItem({ data }: MemberItemProps) {
  const [openDetailModal, setOpenDetailModal] = React.useState(false);

  const toggleOpenDetailModal = () => {
    setOpenDetailModal((prev) => !prev);
  };

  return (
    <>
      <p
        className="font-graebenbach ms-5 my-0"
        data-bs-toggle="modal"
        data-bs-target={`#team_member_detail_modal_${data?.id}`}
        onClick={toggleOpenDetailModal}
      >
        {data?.name}
      </p>
      <TeamMemberDetailModal open={openDetailModal} toggleModal={toggleOpenDetailModal} data={data} />
    </>
  );
}
