import React, { useState } from "react";
import { toast } from "react-toastify";

interface CopyInputProps {
  text: string;
  successMessage?: string;
}

const CopyInput: React.FC<CopyInputProps> = ({ text, successMessage = "Successfully copied the text!" }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      toast.success(successMessage);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  return (
    <div className="input-group mb-3">
      <input type="text" className="form-control" value={text} disabled />
      <button className="btn btn-outline-secondary" onClick={handleClick}>
        {copied ? "Copied!" : "Copy"}
      </button>
    </div>
  );
};

export default CopyInput;
