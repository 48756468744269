import { InstagramEmbed } from "react-social-media-embed";
import { useHandleRouteClick } from "../../../routes/hooks";

interface TicketPaymentConfirmationProps {
  handleNextStep: () => void;
}

export default function TicketPaymentConfirmation({ handleNextStep }: TicketPaymentConfirmationProps) {
  const handleRouteClick = useHandleRouteClick();

  const handleSubmit = () => {
    handleNextStep();
  };

  return (
    <div>
      <div className={`text-center`}>
        <h4 className="font-didot-bold mb-4">Confirmation</h4>
        <div
          style={{
            // height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className={`mb-4`}>
            <p className="font-didot">Your live stream ticket purchase is complete. We look forward to serving you at the show!</p>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1VJSsJu0kh/" width={328} />
            </div>
          </div>
          <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-5" onClick={handleSubmit}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}
