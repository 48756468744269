import React from "react";
import styles from "../styles/QuestionnaireBaseLayout.module.scss";
export interface PortfolioProjectsBaseLayoutProps {
  children?: React.ReactNode;
  handleNext: () => void;
  handlePrevious: () => void;
}

export default function PortfolioProjectsBaseLayout({ children, handleNext, handlePrevious }: PortfolioProjectsBaseLayoutProps) {
  return (
    <div className={styles.questionnaire}>
      <div className="container" style={{ position: "relative" }}>
        {/* <div className="row justify-content-between align-items-center mt-md-auto px-auto px-md-5">
          <div className="w-auto d-flex align-items-center gap-3">
            <button className="my-3 border-0 bg-transparent w-auto" type="button" onClick={() => handlePrevious()}>
              <img src="/arrow_white.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(-90deg)" }} />
            </button>
          </div>
          <div className="w-auto d-flex align-items-center gap-3">
            <button className="my-3 border-0 bg-transparent w-auto" type="button" onClick={() => handleNext()}>
              <img src="/arrow_white.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(90deg)" }} />
            </button>
          </div>
        </div> */}
        {children}
      </div>
    </div>
  );
}
