import React from "react";

export interface UiLoadingProps {
  height: string;
}

const UiLoading: React.FC<UiLoadingProps> = ({ height }) => {
  return (
    <div
      style={{
        display: "flex",
        height: height || "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        background: "#242b3ab0",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 999999,
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          {/* <span className="sr-only">Loading...</span> */}
        </div>
      </div>
      {/* Loader */}
    </div>
  );
};

export default UiLoading;
