import { createSlice } from "@reduxjs/toolkit";

import { captureStripePayment, createStripePaymentIntent, getTicketPurchaseHistory, savePaymentBillingInfo } from "./paymentsThunk";

export interface PaymentsInitialStateProps {
  isLoading: boolean;
  ticketPurchaseHistory: Array<Record<string, any>>;
}

const PaymentsInitialState: PaymentsInitialStateProps = {
  isLoading: false,
  ticketPurchaseHistory: [],
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState: PaymentsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For saving payment billing info
    builder
      .addCase(savePaymentBillingInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(savePaymentBillingInfo.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(savePaymentBillingInfo.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For creating payment intent
    builder
      .addCase(createStripePaymentIntent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createStripePaymentIntent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createStripePaymentIntent.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For capturing payment
    builder
      .addCase(captureStripePayment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(captureStripePayment.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(captureStripePayment.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For payment history
    builder
      .addCase(getTicketPurchaseHistory.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTicketPurchaseHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ticketPurchaseHistory = action.payload.data;
      })
      .addCase(getTicketPurchaseHistory.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const paymentsActions = paymentsSlice.actions;

export default paymentsSlice;
