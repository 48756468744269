import React from "react";
import Input from "react-phone-number-input/input";
import { toast } from "react-toastify";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppDispatch } from "../../store/hooks";
import { contactUs } from "../../store/support/supportThunk";
import styles from "../../styles/Contact.module.scss";

const initialFormData = {
  name: "",
  email: "",
  mobile: "",
  subject: "",
  message: "",
};

export default function ContactPage() {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    dispatch(contactUs(formData))
      .then((res) => {
        if (res?.payload?.status === 201) {
          toast.success("Successfully submitted.");
          setFormData(initialFormData);
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  return (
    <div className={`${styles.form}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <div className="container-fluid px-0 my-2 py-4">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-12 my-3">
                  <input value={formData.name} onChange={handleInputChange} name="name" required type="text" placeholder="Name *" />
                </div>
                <div className="col-12 my-3">
                  <input value={formData.email} onChange={handleInputChange} name="email" required type="email" placeholder="Email *" />
                </div>
                <div className="col-12 my-3">
                  <Input
                    name="mobile"
                    required
                    type="tel"
                    placeholder="Phone *"
                    defaultCountry="US"
                    value={formData.mobile}
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        mobile: String(value),
                      }));
                    }}
                  />
                  {/* <input value={formData.mobile} onChange={handleInputChange} name="mobile" type="text" placeholder="Phone (optional)" /> */}
                </div>
                <div className="col-12 my-3">
                  <input value={formData.subject} onChange={handleInputChange} name="subject" required type="text" placeholder="Subject *" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 my-3">
              <p className="font-didot fw-bold text-start" style={{ fontSize: "24px" }}>
                Message(max 1000 characters)
              </p>
              <textarea
                value={formData.message}
                onChange={(e) => handleInputChange(e)}
                name="message"
                rows={11}
                cols={50}
                className="p-3"
                required
              ></textarea>
            </div>
            <div className="col-12 col-lg-4">
              <p className="font-graebenbach fw-bolder m-0 text-start">Reach us at:</p>
              <p className="font-graebenbach fst-italic m-0 text-start">hxs@hiddenbysoul.com</p>
            </div>
          </div>
          <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-3">
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}
