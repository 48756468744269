import React from "react";

interface VideoRef {
  current: HTMLVideoElement | null;
}

export default function BGVideo() {
  const playerRef1 = React.useRef<VideoRef>(null);
  const playerRef2 = React.useRef<VideoRef>(null);
  const playerRef3 = React.useRef<VideoRef>(null);

  React.useEffect(() => {
    const handlePlay = (ref: VideoRef | null) => {
      ref?.current && // Use optional chaining to handle potential null values
        ref.current
          .play()
          .then(() => {})
          .catch((err) => {
            // Video couldn't play, handle error here.
          });
    };

    handlePlay(playerRef1.current);
    handlePlay(playerRef2.current);
    handlePlay(playerRef3.current);
  }, []);

  return (
    <>
      <video
        ref={playerRef1.current}
        controls={false}
        className="d-none d-sm-none d-lg-block"
        src="/videos/pc1920.mp4"
        playsInline
        loop
        muted
        autoPlay
        style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: -1, objectFit: "cover" }}
      ></video>
      <video
        ref={playerRef2.current}
        controls={false}
        className="d-none d-sm-block d-lg-none"
        src="/videos/tab768.mp4"
        playsInline
        loop
        muted
        autoPlay
        style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: -1, objectFit: "cover" }}
      ></video>
      <video
        ref={playerRef3.current}
        controls={false}
        className="d-block d-sm-none d-lg-none"
        src="/videos/mob368.mp4"
        playsInline
        loop
        muted
        autoPlay
        style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", zIndex: -1, objectFit: "cover" }}
      ></video>
    </>
  );
}
