import React from "react";

import TokenService from "../axios/tokenservice";
import UiLoading from "../layouts/UiLoading";
import { logout } from "../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export default function Logout() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(logout({ refresh: TokenService.getLocalRefreshToken() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{isLoading && <UiLoading height="100vh" />}</div>;
}
