import React from "react";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

export interface MenuItem {
  label: string;
  slug: string;
}

export interface SideMenuSelectorProps {
  menuItems: Array<MenuItem>;
}

export default function SideMenuSelector({ menuItems }: SideMenuSelectorProps) {
  const handleRouteClick = useHandleRouteClick();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setSearchParams({ section: menuItems[0].slug || "profile" });
  }, []);

  return (
    <div>
      {menuItems.map((p, index) => {
        return (
          <p
            key={index}
            className="font-graebenbach m-0"
            style={{ cursor: "pointer", fontStyle: p.slug === searchParams.get("section") ? "italic" : "unset" }}
            onClick={() => {
              handleRouteClick(`${routes.account.path}?section=${p.slug}`);
            }}
          >
            {p.label}
          </p>
        );
      })}
    </div>
  );
}
