import React from "react";
import { getMyDetail } from "../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export interface AppBaseLayoutProps {
  children?: React.ReactNode;
}

export default function AppBaseLayout({ children }: AppBaseLayoutProps) {
  const dispatch = useAppDispatch();
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (isAuthenticated) {
      if (user === null || Object.keys(user).length === 0) {
        dispatch(getMyDetail());
      }
    }
  }, [isAuthenticated]);

  return <div>{children}</div>;
}
