import React from "react";
import { toast } from "react-toastify";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { forgotPassword } from "../../store/auth/authThunk";
import { useAppDispatch } from "../../store/hooks";
import styles from "../../styles/LoginPage.module.scss";
import { SUBMIT_STATUS } from "../../utils/constants";

const initialFormData = {
  email: "",
};

export default function ForgotPassword() {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = React.useState(initialFormData);
  const [currentStatus, setCurrentStatus] = React.useState(SUBMIT_STATUS.INITIAL);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (!formData.email) {
      toast.error("Email is empty!");
      return;
    }
    dispatch(forgotPassword(formData))
      .then((res) => {
        if (res?.payload?.status === 204) {
          toast.success("Please checkl your email for a link to setup your new passowrd.");
          setFormData(initialFormData);
          setCurrentStatus(SUBMIT_STATUS.SUCCESS);
        } else {
          setCurrentStatus(SUBMIT_STATUS.FAILED);
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  return (
    <div className={``}>
      {/* <div className="mt-5">
        <p className="brand-logo-x">X</p>
      </div> */}
      <div className={`${styles.form} my-5 my-md-1`}>
        {currentStatus === SUBMIT_STATUS.INITIAL && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
          >
            <div className="container my-2 py-4">
              <div className="row">
                <div className="col-12">
                  <p className="text-center font-didot fw-bolder mt-5 pb-2">Please enter the email address that you used to signup.</p>
                  <input value={formData.email} onChange={handleInputChange} name="email" required type="email" placeholder="Email" />
                </div>
              </div>
              <button className="my-3 border-0 bg-transparent" type="submit">
                <img src="/arrow.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
              </button>
            </div>
          </form>
        )}
        {currentStatus === SUBMIT_STATUS.SUCCESS && (
          <div>
            <p className="text-center font-didot fw-bolder mt-5 pb-2 d-flex align-items-center" style={{ height: "68vh" }}>
              Please check your email for a link to setup your new password.
            </p>
          </div>
        )}
        {currentStatus === SUBMIT_STATUS.FAILED && (
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "78vh" }}>
            <p className="text-center font-didot fw-bolder mt-5 pb-2">
              The email you have provided is not recognized. Please signup to create your account.
            </p>
            <button className="font-graebenbach submit-button px-4 my-5 my-md-3" type="button" onClick={() => handleRouteClick(routes.register.path)}>
              SIGN UP
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
