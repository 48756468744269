import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import StripeTicketForm from "./components/StripeTicketForm";
import TicketPaymentConfirmation from "./components/TicketPaymentConfirmation";

interface FormStepInterface {
  id: number;
  component: React.ReactElement;
}

export default function LivestreamTicketPurchasePage() {
  const handleRouteClick = useHandleRouteClick();
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector((state) => state.tickets);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = React.useState<number>(parseInt(searchParams.get("step") || "1"));

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} />;
  }

  const handleNextStep = () => {
    setCurrentStep((prev) => {
      const newStep = prev + 1;
      setSearchParams((prev) => {
        const prevParams: Record<string, any> = {};
        prev.forEach((value, key) => {
          prevParams[key] = value;
        });
        return { ...prevParams, step: String(newStep) };
      });
      return newStep;
    });
  };

  const formSteps: FormStepInterface[] = [
    {
      id: 1,
      component: <StripeTicketForm handleNextStep={handleNextStep} />,
    },
    {
      id: 2,
      component: <TicketPaymentConfirmation handleNextStep={() => handleRouteClick(routes.startPage.path)} />,
    },
  ];

  const renderFormStep = () => {
    return formSteps.find((s) => s.id === currentStep)?.component;
  };

  return (
    <div>
      <div className={`mt-4 mb-5`}>{renderFormStep()}</div>
    </div>
  );
}
