import axios from "axios";

import { toast } from "react-toastify";
import { routes } from "../routes";
import { AUTH_LOGIN_API, AUTH_TOKEN_REFRESH_API, BACKEND_BASE_URL } from "../utils/constants";
import TokenService from "./tokenservice";

let isRefreshing: boolean = false;
let failedQueue: Array<any> = [];
var noFurtherToast = false;

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
  // headers: {
  //   "Content-Type": "application/json"
  // },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.code === "ERR_NETWORK") {
      if (!noFurtherToast) {
        toast.error("Apologies for the inconvenience, we will be back soon.");
      }
      noFurtherToast = true;
    }

    const originalConfig = err.config;

    if (
      originalConfig.url !== AUTH_LOGIN_API &&
      // originalConfig.url !== registerEndpoint &&
      err.response
    ) {
      // For no permission issues
      if (err.response.status === 403) {
        if (err?.response?.data?.error) {
          toast.error(`${err.response.data.error}\nContact Administrator!`);
        } else {
          toast.error(`Permission Denied, \nContact Administrator!`);
        }
      }

      if (err.response.status === 500) {
        if (!noFurtherToast) {
          toast.error("Internal Server Issue!");
        }
        noFurtherToast = true;
      }

      // For 400 bas request responses
      // && err?.response?.data?.non_field_errors
      // toast.error(`${err.response.data.non_field_errors}`);
      if (err.response.status === 400) {
        const errors: Array<any> = Object.entries(err.response.data);
        errors.forEach((errorItem) => {
          console.log("errorItem", errorItem);
          if (errorItem[0] === "error") {
            toast.error(errorItem[1]);
          } else {
            if (errorItem[0] !== "non_field_errors") {
              toast.error(`${errorItem[0]}: ${errorItem[1][0]}`);
            } else {
              console.log(`${errorItem[0]}: ${errorItem[1][0]}`);
            }
          }
        });
      }

      if (err.response.status === 401 && originalConfig.url === AUTH_TOKEN_REFRESH_API) {
        // Access Token was expired
        return Promise.reject(err);
      }
      if (err.response.status === 401 && !originalConfig._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalConfig.headers["Authorization"] = `Bearer ${token}`;
              return axiosInstance(originalConfig);
            })
            .catch((err) => {
              console.log(err);
              return Promise.reject(err);
            });
        }

        originalConfig._retry = true;
        isRefreshing = true;
        try {
          const rs = await axiosInstance.post(AUTH_TOKEN_REFRESH_API, {
            refresh: TokenService.getLocalRefreshToken(),
          });
          // console.log(rs);
          const { access, refresh } = rs.data;

          TokenService.updateLocalAccessToken(access);
          TokenService.updateLocalRefreshToken(refresh);

          processQueue(null, access);
          return axiosInstance(originalConfig);
        } catch (_error) {
          processQueue(_error, null);
          TokenService.removeToken();
          TokenService.removeUser();
          window.location.pathname = routes.login.path;
          return Promise.reject(_error);
        } finally {
          isRefreshing = false;
        }
      }
    }
    return Promise.reject(err);
  }
);
export default axiosInstance;
