import React from "react";
import { useParams } from "react-router-dom";
import { routes } from "../../routes";
import { useAppDispatch } from "../../store/hooks";
import { getPartnerDetail } from "../../store/partners/partnerThunk";
import Ticket from "../home/ticket";

export default function PartnerPortal() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [partnerDetail, setPartnerDetail] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    if (params?.partnerSlug) {
      dispatch(getPartnerDetail({ partnerSlug: params.partnerSlug })).then((res) => {
        if (res?.payload?.status === 200) {
          setPartnerDetail(res?.payload?.data);
        } else {
          window.location.href = routes.home.path;
        }
      });
    }
  }, []);

  return (
    <div>
      <div className={`mt-0 mb-5`}>
        <div className="flex text-center mb-4">
          <h4 className="font-didot-bold">{partnerDetail?.description}</h4>
        </div>
        <Ticket isPartnerPortal={true} />
      </div>
    </div>
  );
}
