import { createSlice } from "@reduxjs/toolkit";
import { getPartnerDetail } from "./partnerThunk";

export interface PartnerInitialStateProps {
  isLoading: boolean;
  partnerDetail: Record<string, any>;
}

const PartnerInitialState: PartnerInitialStateProps = {
  isLoading: false,
  partnerDetail: {},
};

const partnerSlice = createSlice({
  name: "partner",
  initialState: PartnerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For getting partner
    builder
      .addCase(getPartnerDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPartnerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partnerDetail = action.payload.data;
      })
      .addCase(getPartnerDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const partnerActions = partnerSlice.actions;

export default partnerSlice;
