import { createSlice } from "@reduxjs/toolkit";

import { getMagazines, getPlatformPortfolio, getPress, getPressTexts } from "./portfolioThunk";

export interface PortfolioInitialStateProps {
  isLoading: boolean;
  platforms: Array<any>;
  presses: Array<any>;
  pressTexts: Array<any>;
  magazines: Array<any>;
}

const PortfolioInitialState: PortfolioInitialStateProps = {
  isLoading: false,
  platforms: [],
  presses: [],
  pressTexts: [],
  magazines: [],
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: PortfolioInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For fetching platform portfolios
    builder
      .addCase(getPlatformPortfolio.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPlatformPortfolio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = action.payload.data;
      })
      .addCase(getPlatformPortfolio.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For fetching platform presses
    builder
      .addCase(getPress.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.presses = action.payload.data;
      })
      .addCase(getPress.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For fetching magazines
    builder
      .addCase(getMagazines.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMagazines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.magazines = action.payload.data;
      })
      .addCase(getMagazines.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For fetching press texts
    builder
      .addCase(getPressTexts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPressTexts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pressTexts = action.payload.data;
      })
      .addCase(getPressTexts.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const portfolioActions = portfolioSlice.actions;

export default portfolioSlice;
