export const containsText = (text: string, searchText: string) => text.toLowerCase().includes(searchText.toLowerCase());

export const randomColorHex = (): string => {
  // Storing all letter and digit combinations
  // for html color code
  let letters = "0123456789ABCDEF";

  // HTML color code starts with #
  let color = "#";

  // Generating 6 times as HTML color code
  // consist of 6 letter or digits
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const textTruncate = (str: string, length: number): string => {
  return str.length > length ? str.substring(0, length) + "..." : str;
};

const buildFormData = (formData: FormData, obj: any, parentKey = "") => {
  if (Array.isArray(obj)) {
    obj.forEach((element) => {
      buildFormData(formData, element, parentKey);
    });
  } else if (typeof obj === "object" && !(obj instanceof File)) {
    Object.keys(obj).forEach((key) => {
      buildFormData(formData, obj[key], parentKey ? `${parentKey}.${key}` : key);
    });
  } else {
    if (obj == null) {
      return;
    }

    const value = typeof obj === "number" || typeof obj === "boolean" ? obj.toString() : obj;
    formData.append(parentKey, value);
  }
};

export const objectToFormData = (obj: any) => {
  const formData = new FormData();
  buildFormData(formData, obj);
  return formData;
};

export const convertDateTimeToMonthYear = (datetimeString: string) => {
  // Create a new Date object from the string
  const date = new Date(datetimeString);

  // Get the month name (September)
  const monthName = date.toLocaleString("default", { month: "long" });

  // Get the year (2023)
  const year = date.getFullYear();

  // Combine the month and year
  const formattedDate = `${monthName} ${year}`;

  return formattedDate;
};

export const convertDateTimeToDayMonthYear = (datetimeString: string) => {
  // Create a new Date object from the string
  const date = new Date(datetimeString);

  // Get the month name (September)
  const monthName = date.toLocaleString("default", { month: "long" });

  // Get the year (2023)
  const year = date.getFullYear();

  // Combine the month and year
  const formattedDate = `${date.getDate()}/${monthName}/${year}`;

  return formattedDate;
};
