import React from "react";
import Input from "react-phone-number-input/input";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { login, register } from "../../../store/auth/authThunk";
import { useAppDispatch } from "../../../store/hooks";
import styles from "../../../styles/TicketBillingInfo.module.scss";

interface TicketContactInfoProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

const initialFormData = {
  full_name: "",
  email: "",
  mobile: "",
  industry: "",
};

export default function TicketContactInfo({ handleNextStep, isPartnerPortal = false }: TicketContactInfoProps) {
  const params = useParams();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    console.log("formData", formData);
    if (!formData.email && !formData.mobile) {
      toast.error("Please fill all the required fields.");
      return;
    }

    const username = formData.email.split("@")[0].toLowerCase();
    dispatch(
      register({
        username: username,
        email: formData.email.toLowerCase(),
        full_name: formData.full_name,
        mobile: formData.mobile,
        password: formData.email.toLowerCase(),
        industry: formData.industry,
        partner_slug: params?.partnerSlug,
      })
    )
      .then((res) => {
        if (res?.payload?.status === 201) {
          // toast.success("Successfully submitted.");
          setFormData(initialFormData);
          dispatch(login({ username: username, password: formData.email.toLowerCase() }));
          handleNextStep();
          localStorage.setItem("partnerPortalUser", res?.payload?.data); // Storing in local storage to use it later
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  return (
    <div>
      {!isPartnerPortal && (
        <div className="my-3 d-flex justify-content-between">
          <div>
            <a href={routes.home.path}>
              <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                Home
              </h4>
            </a>
          </div>
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        </div>
      )}
      <div className={`text-center`}>
        <h4 className="font-didot-bold mb-5 pb-5 pb-md-0 mb-md-4">Contact Info</h4>
        <div
          style={{
            height: "62vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={`mb-4 ${styles.form}`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit();
              }}
            >
              <div className="container px-0 my-2 py-4">
                <div className="row">
                  <div className="col-12  my-3">
                    <input
                      value={formData.full_name}
                      className=""
                      onChange={handleInputChange}
                      name="full_name"
                      required
                      type="text"
                      placeholder="Full Name *"
                    />
                  </div>
                  <div className="col-12  my-3">
                    <input value={formData.email} onChange={handleInputChange} name="email" required type="email" placeholder="Email *" />
                  </div>
                  <div className="col-12  my-3">
                    <Input
                      name="mobile"
                      required={true}
                      type="tel"
                      placeholder="Phone *"
                      defaultCountry="US"
                      value={formData.mobile}
                      className=""
                      onChange={(value) => {
                        setFormData((prev) => ({
                          ...prev,
                          mobile: String(value),
                        }));
                      }}
                    />
                  </div>
                  <div className="col-12  my-3">
                    <input className="" onChange={handleInputChange} name="industry" type="text" placeholder="Industry (Optional)" />
                  </div>
                </div>
                <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-5">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
