import React from "react";
import { FiEdit } from "react-icons/fi";
import { IoCloseSharp, IoSaveOutline } from "react-icons/io5";
import { FileData } from "./answers/FilesSelectAnswer";

export interface FileDetailCardProps {
  fileDataObj: FileData;
  handleChangeDescription: (description: string) => void;
  handleRemove: () => void;
}

export default function FileDetailCard({ fileDataObj, handleChangeDescription, handleRemove }: FileDetailCardProps) {
  const [editMode, setEditMode] = React.useState(false);

  const fileName =
    "file" in fileDataObj && typeof fileDataObj.file === "string"
      ? fileDataObj?.file?.substring(fileDataObj?.file?.lastIndexOf("/") + 1)
      : fileDataObj.fileObj?.name;

  return (
    <div className="d-flex flex-row justify-content-between gap-3 px-3 py-2" style={{ border: "1px solid", borderRadius: "8px" }}>
      <p>{fileName}</p>
      <div className="d-flex gap-3 align-items-center">
        {!fileDataObj?.id && (
          <>
            {editMode ? (
              <input
                placeholder="Describe this file"
                type="text"
                defaultValue={fileDataObj.description}
                onChange={(e) => handleChangeDescription(e.target.value)}
              />
            ) : (
              <p>{fileDataObj.description || "Describe this file"}</p>
            )}
            {editMode ? <IoSaveOutline onClick={() => setEditMode((prev) => !prev)} /> : <FiEdit onClick={() => setEditMode((prev) => !prev)} />}
          </>
        )}
        <IoCloseSharp onClick={() => handleRemove()} />
      </div>
    </div>
  );
}
