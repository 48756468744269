import { useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import TicketPaymentForm from "./components/TicketPaymentForm";

interface TicketStripePaymentProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

const initialFormData = {
  ticket_count: 0,
  card: "",
  expiry: "",
  cvc: "",
};

export default function TicketStripePayment({ handleNextStep, isPartnerPortal }: TicketStripePaymentProps) {
  const handleRouteClick = useHandleRouteClick();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = () => {
    // toast.success("Successfully submitted.");
    handleNextStep();
  };

  if (searchParams.get("client_secret") && searchParams.get("ticket_count")) {
    return (
      <div>
        {!isPartnerPortal && (
          <div className="my-3 d-flex justify-content-between">
            <div>
              <a href={routes.home.path}>
                <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                  Home
                </h4>
              </a>
            </div>
            <div>
              <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
                Log Out
              </h4>
            </div>
          </div>
        )}
        <TicketPaymentForm handleSubmit={handleSubmit} />
      </div>
    );
  } else {
    return <>Loading Stripe...</>;
  }
}
