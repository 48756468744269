import React from "react";
import styles from "../../styles/RegisterPage.module.scss";

export interface TextAnswerProps {
  getAnswer: (value: string) => any;
  userAnswer: string;
}

export default function TextAnswer({ getAnswer, userAnswer }: TextAnswerProps) {
  const [value, setValue] = React.useState(userAnswer || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    getAnswer(value);
  }, [value]);

  return (
    <div className={styles["form"]}>
      <input value={value} type="text" name="text" onChange={handleInputChange} required placeholder="" />
    </div>
  );
}
