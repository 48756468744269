import { Project } from "..";
import styles from "../../../styles/ProjectCard.module.scss";

export interface ProjectCardProps {
  project: Project;
}

export default function ProjectCard({ project }: ProjectCardProps) {
  return (
    <div className="w-100">
      <img className={`mb-4 ${styles["project-card-img"]}`} src={project?.primary_thumbnail?.image || "/placeholder-vertical.jpg"} alt="thumbnail" />
      <h5 className="font-didot-bold m-0">{project.platform_name}</h5>
      <a href={project.redirect_url} target="_blank" rel="noreferrer">
        <h5 className="font-didot-bold m-0">{project.label}</h5>
      </a>
      <h5 className="font-didot fw-medium m-0">{project.location}</h5>
    </div>
  );
}
