import { createSlice } from "@reduxjs/toolkit";
import TokenService from "../../axios/tokenservice";

import {
  changePassword,
  forgotPassword,
  forgotPasswordConfirm,
  getMyDetail,
  login,
  logout,
  register,
  saveConsentAgreement,
  updateMyDetail,
} from "./authThunk";

export interface AuthInitialState {
  isLoading: boolean;
  user: Record<string, any> | null;
  token: Record<string, any> | null;
  isAuthenticated: boolean;
}

const authInitialState: AuthInitialState = {
  isLoading: false,
  user: TokenService.getUser(),
  token: TokenService.getToken(),
  isAuthenticated: Boolean(TokenService.getLocalAccessToken()),
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For login
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload.data;
        state.isAuthenticated = true;
        // Set localstorage
        TokenService.setToken(action.payload.data);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For registration
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For logout
    builder
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
        state.user = null;
        state.isAuthenticated = false;
        // Set localstorage
        TokenService.removeUser();
        TokenService.removeToken();
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        localStorage.clear();
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For User details
    builder
      .addCase(getMyDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMyDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
        TokenService.setUser(action.payload.data);
      })
      .addCase(getMyDetail.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Change Password
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Forgot Password
    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Forgot Password Confirm
    builder
      .addCase(forgotPasswordConfirm.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(forgotPasswordConfirm.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(forgotPasswordConfirm.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Saving Consent Agreement
    builder
      .addCase(saveConsentAgreement.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveConsentAgreement.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveConsentAgreement.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For Updating User
    builder
      .addCase(updateMyDetail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateMyDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.data;
        TokenService.setUser(action.payload.data);
      })
      .addCase(updateMyDetail.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice;
