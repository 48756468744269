import React from "react";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAboutPageText } from "../../store/settings/settingsThunk";
import styles from "../../styles/About.module.scss";

export default function AboutPage() {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const { aboutPage } = useAppSelector((state) => state.settings);

  React.useEffect(() => {
    dispatch(getAboutPageText());
  }, []);

  return (
    <div className={`container mb-5 mt-5 pb-5 ${styles.about}`}>
      <div className="row my-5">
        <div className="col-12 my-2">
          <h4 className="font-didot-bold text-start">{aboutPage?.title}</h4>
        </div>
        <div className="col-12 col-md-3 my-2">
          <img className="img-fluid" src="/services/art.jpeg" alt="xm" />
        </div>
        <div className="col-12 col-md-9 my-2">
          <div className=" text-start mb-4">
            <div dangerouslySetInnerHTML={{ __html: aboutPage?.content }}></div>
          </div>
          {/* <h5 className="font-graebenbach fw-bold text-start mb-4">
            What's the value of going out anymore when entertainment is 2 remote control clicks, dating is a swipe right, and opportunity is a
            recuiter bot.
            <br />
            <br />
            Does convenience and tons of options get you the best?
            <br />
            <br />
            What if you got a curated experience of the BEST entertainment, cuisine, and people?
            <br />
            <br />
            Not only that, the world's most difficult situations that have you pushing your mental health, we solve them here.
            <br />
            <br />
            <a className="text-primary" href={routes.register.path}>
              SIGN UP
            </a>{" "}
            or{" "}
            <a className="text-primary" href={routes.login.path}>
              SIGN IN
            </a>{" "}
            to get your tickets NOW. Next show is on Science, Religion, and Aliens!
            <br />
            <br />
            And the people you will meet. No more Tinder, no more Ziprecruiter. We attract souls seeking the sacred arts all in one place.
            <br />
            <br />
            Friday, October 25th, 2024 in a landmark Gothic Church, Brooklyn, NY. For tickets and details:
          </h5> */}
          <button
            style={{ background: "#9a3a2ec7", color: "white", whiteSpace: "nowrap" }}
            className="font-graebenbach submit-button px-4 mt-4"
            type="button"
            onClick={() => handleRouteClick(routes.register.path)}
          >
            SIGN UP
          </button>
        </div>
      </div>
    </div>
  );
}
