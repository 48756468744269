import React from "react";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import { useHandleRouteClick } from "../../routes/hooks";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPlatformPortfolio } from "../../store/portfolio/portfolioThunk";
import styles from "../../styles/Portfolio.module.scss";
import ProjectCard from "./components/ProjectCard";
import SidePlatformSelector from "./components/SidePlatformSelector";

export interface Project {
  label: string;
  slug: string;
  platform_name: string;
  location: string;
  collaboration?: string;
  project_type: string;
  redirect_url: string;
  primary_thumbnail?: Record<string, any>;
  thumbnails?: Array<Record<string, any>>;
}

export default function PortfolioPage() {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [searchParams, setSearchParams] = useSearchParams();
  const { platforms } = useAppSelector((state) => state.portfolio);
  const [projects, setProjects] = React.useState<Array<Project>>([]);

  const platformName = searchParams.get("platformName");

  if (!platformName) {
    setSearchParams({ platformName: "all" });
  }

  React.useEffect(() => {
    dispatch(getPlatformPortfolio());

    if (!platformName) {
      setSearchParams({ platformName: "all" });
    }
  }, []);

  React.useEffect(() => {
    if (platformName === "all") {
      setProjects(platforms.flatMap((p) => p.portfolio_projects));
    } else if (platformName != null) {
      setProjects(platforms.find((p) => p.slug === platformName)?.portfolio_projects);
    }
  }, [platformName, platforms, searchParams]);

  return (
    <div className="container-fluid pt-4 pb-5 px-0 px-md-auto">
      <div className="row position-relative">
        <div className={`col-0 col-md-3 mb-auto mb-md-auto ${styles["side-platform-selector"]}`}>
          <SidePlatformSelector platforms={platforms} />
        </div>
        <div className={"col-12 col-md-9"}>
          <div className="row">
            <div className="col-12">
              <h5 className="font-didot-bold text-start">
                If you love our shows, you will love the problem solving nature of our design-build work.{" "}
                <a className="text-primary" href={routes.register.path}>
                  SIGN UP
                </a>{" "}
                and click on{" "}
                <a className="text-primary" href={routes.dreamHub.path}>
                  Dream Hub
                </a>{" "}
                for a free consultaion.
                <br />
                <br />
              </h5>
            </div>
            {projects?.length > 0 ? (
              projects.map((p, index) => {
                return (
                  <div
                    className="col-8 col-md-6 d-flex justify-content-center pb-5"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRouteClick(routes.portfolioProject.path.replaceAll(":projectSlug", p.slug))}
                  >
                    <ProjectCard project={p} />
                  </div>
                );
              })
            ) : (
              <div className="col-8 col-md-6 d-flex justify-content-center pb-5">
                <p className="text-center">No Projects Found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
