import React from "react";
import CurrencyInput from "react-currency-input-field";
import styles from "../../styles/RegisterPage.module.scss";

export interface CurrencyAnswerProps {
  getAnswer: (value: string) => any;
  userAnswer: string;
}

export default function CurrencyAnswer({ getAnswer, userAnswer }: CurrencyAnswerProps) {
  const [value, setValue] = React.useState(userAnswer || "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    getAnswer(value);
  }, [value]);

  return (
    <div className={styles["form"]}>
      <CurrencyInput
        required
        id="currency"
        prefix="$"
        name="currency"
        placeholder="$"
        defaultValue={value}
        decimalsLimit={2}
        onValueChange={(value, name) => setValue(value || "0")}
      />
      {/* <input value={value} type="number" name="currency" onChange={handleInputChange} required placeholder="$ " /> */}
    </div>
  );
}
