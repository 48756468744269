import React from "react";
import PDFViewerModal from "../../components/PDFViewer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getMagazines } from "../../store/portfolio/portfolioThunk";
import styles from "../../styles/Magazine.module.scss";
import { convertDateTimeToMonthYear } from "../../utils/helper";

interface MagazineCardProps {
  data: Record<string, any>;
}

const MagazineCard = ({ data }: MagazineCardProps) => {
  return (
    <div>
      <img
        loading="lazy"
        alt="portfolio"
        src={data.image || "/placeholder-vertical.jpg"}
        style={{ height: "100%", width: "100%", objectFit: "cover", border: "1px solid black" }}
        className="mb-3 img-fluid"
      />
      <p className="m-0 font-didot-bold">{data.label}</p>
      <p className="m-0 font-didot fw-medium">{convertDateTimeToMonthYear(data.date)}</p>
      <p className="m-0 font-didot-bold">{data.description}</p>
    </div>
  );
};

const Magazine = ({ data }: MagazineCardProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  if (data.pdf_file) {
    return (
      <React.Fragment key={data.id}>
        <div
          className="modal fade m-0 p-0"
          id={`magazineModal_${data.id}`}
          tabIndex={-1}
          aria-labelledby={`magazineModal_${data.id}Label`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black font-didot-bold" id="exampleModalToggleLabel">
                  {data.label}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={toggleModal}></button>
              </div>
              <div className="modal-body">{data.pdf_file && <PDFViewerModal open={openModal} toggle={toggleModal} pdfUrl={data.pdf_file} />}</div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3 text-start text-md-center mb-5">
          <div style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={`#magazineModal_${data.id}`} onClick={toggleModal}>
            <MagazineCard data={data} />
          </div>
        </div>
      </React.Fragment>
    );
  } else if (data.link) {
    return (
      <div className="col-6 col-md-3 text-start text-md-center mb-5" key={data.id}>
        <a target="_blank" rel="noreferrer" href={data.link}>
          <MagazineCard data={data} />
        </a>
      </div>
    );
  } else {
    return (
      <React.Fragment key={data.id}>
        <div
          onClick={toggleModal}
          className="modal fade m-0 p-0"
          id={`magazineModal_${data.id}`}
          tabIndex={-1}
          aria-labelledby={`magazineModal_${data.id}Label`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-black font-didot-bold" id="exampleModalToggleLabel">
                  {data.label}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={toggleModal}></button>
              </div>
              <div className="modal-body center">
                <h3 className="text-center text-black font-didot-medium">Coming Soon...</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-md-3 text-start text-md-center mb-5">
          <div style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target={`#magazineModal_${data.id}`}>
            <MagazineCard data={data} />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default function MagazinePage() {
  const dispatch = useAppDispatch();
  const { magazines } = useAppSelector((state) => state.portfolio);

  React.useEffect(() => {
    dispatch(getMagazines());
  }, []);

  return (
    <div className={`container-fluid ${styles["magazine"]}`}>
      <div className="row mb-5 pb-5 justify-content-center">
        {magazines.length > 0 ? (
          magazines.map((data, index) => {
            return <Magazine data={data} key={index} />;
          })
        ) : (
          <div className="text-center">
            <p>No Magazines Found</p>
          </div>
        )}
      </div>
    </div>
  );
}
