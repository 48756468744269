import { Link } from "react-router-dom";
import { routes } from "../../routes";

export default function XMPage() {
  return (
    <div>
      <div className="mt-5">
        {/* <p className="brand-logo-x">X</p> */}
        <p className="text-center font-graebenbach">XM</p>
      </div>
      <div
        className="text-center"
        style={{
          height: "60vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="font-didot">
          For more details on our experiential marketing services, please email us at{" "}
          <strong>
            <a style={{ cursor: "pointer" }} href="mailto:hxs@hiddenbysoul.com">
              hxs@hiddenbysoul.com
            </a>
          </strong>
        </p>
        <p className="font-didot">
          To see our body of work, visit&nbsp;
          <strong>
            <Link to={routes.portfolio.path}>design services.</Link>
          </strong>
        </p>
        {/* <p className="font-didot">
          Thank you for your submission. Someone from our team will be in touch with you to discuss your needs in further detail.
        </p>
        <p className="font-didot">
          You can contact us at <strong>hxs@hiddenbysoul.com</strong>
        </p>
        <p className="font-didot">
          For more information or for an invite, visit <strong>hiddenbysoul.com</strong>
        </p> */}
      </div>
    </div>
  );
}
