import React from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";

interface TicketPaymentConfirmationProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

export default function TicketPaymentConfirmation({ handleNextStep, isPartnerPortal = false }: TicketPaymentConfirmationProps) {
  const handleRouteClick = useHandleRouteClick();

  const handleSubmit = () => {
    handleNextStep();
  };

  React.useEffect(() => {
    // Set a timer to redirect after n seconds
    const timer = setTimeout(() => {
      window.location.href = routes.home.path;
    }, 30000);

    // Cleanup the timeout if the component unmounts before n seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {!isPartnerPortal && (
        <div className="my-3 d-flex justify-content-between">
          <div>
            <a href={routes.home.path}>
              <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                Home
              </h4>
            </a>
          </div>
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        </div>
      )}
      <div className={`text-center`}>
        <h4 className="font-didot-bold mb-4">Confirmation and Prize</h4>
        <div
          style={{
            // height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className={`mb-4`}>
            <p className="font-didot">Your ticket purchase is complete. We look forward to meeting you at the show!</p>
            <p className="font-didot">
              A lucky winner will be selected to win 2 front row seats and a backstage tour with our brand partner, Peter Doremus.
            </p>
            <p className="font-didot">
              To enter the draw and for a chance to win, contact him at
              <a className="text-decoration-none fw-bolder" href="mailto:pete.doremus@gmail.com">
                &nbsp;pete.doremus@gmail.com&nbsp;
              </a>
              or follow and DM him on Instagram by clicking the video below.
            </p>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url="https://www.instagram.com/reel/C1VJSsJu0kh/" width={328} />
            </div>
          </div>
          <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-5" onClick={handleSubmit}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}
