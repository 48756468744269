import React from "react";
import YouTubePopup from "../../../components/YouTubePopup";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getMyDetail } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getTeamMembersListAPI } from "../../../store/settings/settingsThunk";
import { getEventPageText } from "../../../store/tickets/ticketsThunk";
import LiveStreamModal from "./components/LiveStreamModal";
import MemberItem from "./components/MemberItem";

interface ExpandCollapseProps {
  title: string;
  children: React.ReactElement;
}

export const ExpandCollapse = ({ title, children }: ExpandCollapseProps) => {
  const [expand, setExpand] = React.useState(false);

  const toggleExpand = () => {
    setExpand((prev) => !prev);
  };

  return (
    <div>
      <p className="font-graebenbach fst-italic my-0" style={{ cursor: "pointer" }} onClick={toggleExpand}>
        {title}
      </p>
      <>{expand && children}</>
    </div>
  );
};

interface TicketPurchaseProps {
  handleNextStep: () => void;
  handleLiveStreamNextStep: () => void;
  isPartnerPortal?: boolean;
}

export default function TicketPurchaseDetail({ handleNextStep, handleLiveStreamNextStep, isPartnerPortal = false }: TicketPurchaseProps) {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const { settings, eventPage } = useAppSelector((state) => state.tickets);
  const { user } = useAppSelector((state) => state.auth);
  const [openLiveStreamModal, setLiveStreamOpenModal] = React.useState<boolean>(false);
  const [creativeTeamMembers, setCreativeTeamMembers] = React.useState<Record<string, any>[]>([]);

  const toggleLiveStreamModal = () => {
    setLiveStreamOpenModal((prev) => !prev);
  };

  React.useEffect(() => {
    dispatch(getEventPageText());
  }, []);

  React.useEffect(() => {
    !isPartnerPortal && dispatch(getMyDetail());
    dispatch(getTeamMembersListAPI()).then((res) => {
      if (res.payload?.status === 200) {
        let memberData_: Record<string, any> = res.payload.data.reduce((acc: Record<string, any>, member: Record<string, any>) => {
          const category = member.category;
          // Initialize an empty array for the category if it doesn't exist
          acc[category] = acc[category] || [];
          acc[category].push(member); // Push the member object to the category array
          return acc;
        }, {});
        setCreativeTeamMembers(Object.entries(memberData_));
      }
    });
  }, []);

  return (
    <div>
      <div className={`${isPartnerPortal ? "mt-0" : "my-3"} d-flex justify-content-${isPartnerPortal ? "center" : "between"}`}>
        {!isPartnerPortal && (
          <div>
            <a href={routes.home.path}>
              <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                Home
              </h4>
            </a>
          </div>
        )}
        <div className={`d-none d-md-flex flex-column ${isPartnerPortal ? "my-0" : "my-4"} position-relative`}>
          <button
            className="px-4"
            style={settings?.available_tickets <= 0 ? {} : { background: "#9a3a2ec7", color: "white", whiteSpace: "nowrap" }}
            disabled={settings?.available_tickets <= 0}
            onClick={() => settings?.available_tickets > 0 && handleNextStep()}
          >
            GET MY TICKET
          </button>
          {settings?.available_tickets <= 0 && (
            <button
              className="px-4 position-absolute"
              onClick={() => {}}
              disabled
              style={{ transform: "rotate(-12deg)", width: "100%", background: "#9a3a2ec7", color: "white" }}
            >
              SOLD OUT
            </button>
          )}
          <>
            {settings?.show_live_stream && (
              <>
                <button
                  type="button"
                  className="px-4 mt-3"
                  style={{ whiteSpace: "nowrap", alignSelf: "center" }}
                  data-bs-toggle={user?.has_purchased_atleast_one_ticket ? "modal" : ""}
                  data-bs-target={user?.has_purchased_atleast_one_ticket ? "#livestream_modal" : ""}
                  onClick={user?.has_purchased_atleast_one_ticket ? toggleLiveStreamModal : handleLiveStreamNextStep}
                >
                  LIVESTREAM
                </button>
                <LiveStreamModal open={openLiveStreamModal} toggleModal={toggleLiveStreamModal} streamURL={settings?.stream_url} />
              </>
            )}
          </>
          {!settings?.show_live_stream && (
            <button className="border-0 bg-transparent" type="submit">
              <img src="/arrow_red.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
            </button>
          )}
        </div>
        {!isPartnerPortal && (
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        )}
      </div>
      <div className="container-fluid px-0">
        <YouTubePopup open={openLiveStreamModal} toggleModal={toggleLiveStreamModal} streamURL={settings?.promo_url} />
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row my-3 d-flex d-md-none">
              <div className="col-12 d-flex flex-column my-2">
                <button
                  className="px-4"
                  style={{ background: "#9a3a2ec7", color: "white", whiteSpace: "nowrap", width: "fit-center", alignSelf: "center" }}
                  onClick={() => handleNextStep()}
                >
                  GET MY TICKET
                </button>
                {settings?.available_tickets <= 0 && (
                  <button
                    className="px-4 position-absolute"
                    onClick={() => {}}
                    disabled
                    style={{ transform: "rotate(-12deg)", width: "100%", background: "#9a3a2ec7", color: "white" }}
                  >
                    SOLD OUT
                  </button>
                )}
                <>
                  {settings?.show_live_stream && (
                    <>
                      <button
                        type="button"
                        className="px-4 mt-3"
                        style={{ whiteSpace: "nowrap", alignSelf: "center" }}
                        data-bs-toggle={user?.has_purchased_atleast_one_ticket ? "modal" : ""}
                        data-bs-target={user?.has_purchased_atleast_one_ticket ? "#livestream_modal" : ""}
                        onClick={user?.has_purchased_atleast_one_ticket ? toggleLiveStreamModal : handleLiveStreamNextStep}
                      >
                        LIVESTREAM
                      </button>
                      <LiveStreamModal open={openLiveStreamModal} toggleModal={toggleLiveStreamModal} streamURL={settings?.stream_url} />
                    </>
                  )}
                </>
                {!settings?.show_live_stream && (
                  <button className="my-1 border-0 bg-transparent" type="submit">
                    <img src="/arrow_red.svg" alt="arrow" style={{ height: "2rem", transform: "rotate(0deg)" }} />
                  </button>
                )}
              </div>
              <div className="col-6 d-flex flex-column my-4">
                <h4 className="font-didot-bold">Date</h4>
                <h6 className="font-didot">
                  OCTOBER 25th, 2024
                  <br />
                  7:00pm - 10:00pm
                </h6>
              </div>
              <div className="col-6 d-flex flex-column my-4">
                <h4 className="font-didot-bold">Location</h4>
                <h6 className="font-didot">First Unitarian Congregational Society in Brooklyn</h6>
                <h6 className="font-didot">119-121 Pierrepont Street</h6>
                <h6 className="font-didot">Brooklyn, NY 11201</h6>
              </div>
            </div>
            <div className={`text-center mb-3 d-md-none d-block`} style={{}}>
              {/* Mobile */}
              <button className="px-4 mt-3 border-0" data-bs-toggle={"modal"} data-bs-target={"#promo_modal"} onClick={toggleLiveStreamModal}>
                <img src={settings?.promo_image} alt="promo" style={{ height: "24rem" }} />
              </button>
              {/* <InstagramEmbed url={settings?.promo_url || ""} /> */}
              {/* <iframe
                src={settings?.promo_url?.replaceAll("shorts", "embed")}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
            </div>
            <div className="my-3 d-md-flex justify-content-between d-none">
              <div className="d-flex flex-column my-4">
                <h4 className="font-didot-bold">Date</h4>
                <h6 className="font-didot">
                  OCTOBER 25th, 2024
                  <br />
                  7:00pm - 10:00pm
                </h6>
              </div>
              <div className="d-flex flex-column my-4">
                <h4 className="font-didot-bold">Location</h4>
                <h6 className="font-didot">First Unitarian Congregational Society in Brooklyn</h6>
                <h6 className="font-didot">119-121 Pierrepont Street</h6>
                <h6 className="font-didot">Brooklyn, NY 11201</h6>
              </div>
            </div>

            <div className="text-start mb-5">
              <div dangerouslySetInnerHTML={{ __html: eventPage?.content }}></div>
            </div>

            {/* <div className="mb-5">
              <p className="m-0 font-graebenbach-bold fw-bold">Event Description</p>
              <p className="mb-3 font-graebenbach">
                A ONCE in a lifetime NYC event that will make meeting humans face to face more worth your while than your favorite night in of Netflix
                and Door Dash. <br />
                <br />
                And the real answers to science, religion, and aliens.
                <br />
                <br />
                Not something you can find in an episode of Ancient Aliens. <br />
                <br />
                So what’s all the hype? What is in this full immersive experience?
                <br />
                <br />
                EveryTHING and everyONE, including yourself, is curated. <br />
                <br />
                You get a night of live music, dance, theatrical performances. <br />
                <br />
                You get fed a delicious meal. <br />
                <br />
                We have a fashion show. <br />
                <br />
                And the people you will meet. No more Tinder, no more Ziprecruiter. We attract souls seeking the sacred arts all in one place. <br />
                <br />
                So whatever you are looking for. Whoever you are looking for. It’s here. <br />
                <br />
                Take the introspective dive into the philosophy.
                <br />
              </p>
            </div>
            <div className="mb-5">
              <p className="m-0 font-graebenbach-bold fw-bold">ZERO Philosophy</p>
              <p className="mb-3 font-graebenbach">
                What if we embarked on a journey of contemplation that transcends the boundaries between belief and disbelief, seeking a shared
                understanding that unites us all? Imagine exploring narratives from both religious and non-religious perspectives, striving to find a
                definition of the divine that resonates universally.
                <br />
                <br />
                Let’s consider the stories that have shaped civilizations: the miraculous births, the parting of seas, the conversations between
                historical figures, and the celestial dreams. Could these stories hold truths that are both literal and metaphorical, appealing to
                believers and non-believers alike? What if the essence of revelation lay not solely in the details, but in the underlying messages
                that transcend religious boundaries?
                <br />
                <br />
                Contemplate the concept that heaven and hell could represent our inner states, not just physical destinations. Could these realms
                symbolize the consequences of our choices and actions, regardless of one’s theological stance? And what if the scriptures, cherished
                by believers, were influenced by the gradual evolution of interpretations across generations, blending human understanding with divine
                wisdom?
                <br />
                <br />
                In a world where perspectives diverge, the art of discernment becomes vital. Balancing intuition and intellect, believers and
                non-believers alike seek answers that resonate with their inner truth. Some draw comfort from circular philosophies, while others
                contemplate moral hierarchies or personal purpose.
                <br />
                <br />
                In the midst of a world facing moral complexities and competing priorities, the quest for spiritual truth often takes a back seat.
                Leaders may prioritize power over compassion, and societies may prioritize correctness over empathy. So let’s ask ourselves, what if
                the pursuit of knowledge led us to humility instead of arrogance? Could we envision a collective pursuit of love for all and animosity
                for none, uniting humanity in a shared aspiration?
                <br />
                <br />
                Amid the diverse tapestry of beliefs, let’s embark on this shared exploration, inviting dialogue that transcends divisions and fosters
                understanding. In this space of unity, we seek a definition of the divine that bridges the gap between believers and non-believers,
                creating a universal platform for reflection and connection.
                <br />
              </p>
            </div>
            <div className="mb-5">
              <p className="m-0 font-graebenbach-bold fw-bold">Vision and Values</p>
              <p className="mb-3 font-graebenbach">
                To restore the sanctity of art and to serve humanity by bringing together like-minded creatives of the world. At HXS, we believe in
                the following values:
                <br />
                <br />
                Context of speech.
                <br />
                Actions speak louder than likes.
                <br />
                Reward originality.
                <br />
                Social welfare all our shows are associated with a welfare cause.
                <br />
              </p>
            </div>
            <div className="mb-5">
              <p className="m-0 font-graebenbach-bold fw-bold">HXS House Rules</p>
              <p className="mb-3 font-graebenbach">
                No vulgarity, profanity, or intoxicants will be allowed on the show <br />
                Observe silence during performances <br />
                All tickets purchased for HXS events are final. We do not issue refunds or exchanges unless the show is cancelled. <br />
              </p>
            </div> */}
          </div>
          <div className="col-12 col-md-6">
            <div className={`text-center mb-3 d-md-block d-none`} style={{}}>
              {/* Desktop */}
              <button className="px-4 mt-3 border-0" data-bs-toggle={"modal"} data-bs-target={"#promo_modal"} onClick={toggleLiveStreamModal}>
                <img src={settings?.promo_image} alt="promo" style={{ height: "24rem" }} />
              </button>
              {/* <InstagramEmbed url={settings?.promo_url || ""} width={328} /> */}
              {/* <iframe
                src={settings?.promo_url.replaceAll("shorts", "embed")}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
            </div>
            <hr className="d-block d-md-none my-5 bg-white m-auto w-75" />
            <div className="container-fluid">
              <h4 className="font-didot-bold text-center text-md-start">Creative Team Biographies</h4>
              <div className="row w-100">
                <div className="col-4 w-100 offset-2 offset-md-0 col-md-12">
                  {creativeTeamMembers.map((v_, index) => {
                    return (
                      <ExpandCollapse key={index} title={`(${index + 1}) ${v_[0]}`}>
                        <>
                          {v_[1]?.map((m_: Record<string, any>) => {
                            return <MemberItem data={m_} />;
                          })}
                        </>
                      </ExpandCollapse>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
