import React from "react";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppDispatch } from "../../../store/hooks";
import { savePaymentBillingInfo } from "../../../store/payments/paymentsThunk";
import styles from "../../../styles/TicketBillingInfo.module.scss";

interface TicketBillingInfoProps {
  handleNextStep: () => void;
  isPartnerPortal?: boolean;
}

const initialFormData = {
  country: "",
  zip_code: "",
  city: "",
  address: "",
  state: "",
};

export default function TicketBillingInfo({ handleNextStep, isPartnerPortal = false }: TicketBillingInfoProps) {
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [formData, setFormData] = React.useState(initialFormData);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    dispatch(savePaymentBillingInfo(formData))
      .then((res) => {
        if (res?.payload?.status === 201) {
          // toast.success("Successfully submitted.");
          setFormData(initialFormData);
          handleNextStep();
        } else {
          toast.error(res.payload.error.data.detail);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Oops! Some issues while submitting.");
      });
  };

  return (
    <div>
      {!isPartnerPortal && (
        <div className="my-3 d-flex justify-content-between">
          <div>
            <a href={routes.home.path}>
              <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
                Home
              </h4>
            </a>
          </div>
          <div>
            <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
              Log Out
            </h4>
          </div>
        </div>
      )}
      <div className={`text-center`}>
        <h4 className="font-didot-bold mb-5 pb-5 pb-md-0 mb-md-4">Billing Address</h4>
        <div
          style={{
            height: "62vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={`mb-4 ${styles.form}`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit();
              }}
            >
              <div className="container px-0 my-2 py-4">
                <div className="row">
                  <div className="col-12 col-md-4 my-3">
                    <input value={formData.country} onChange={handleInputChange} name="country" required type="text" placeholder="Country" />
                  </div>
                  <div className="col-12 col-md-4 my-3">
                    <input value={formData.zip_code} onChange={handleInputChange} name="zip_code" required type="text" placeholder="Zip Code" />
                  </div>
                  <div className="col-12 col-md-4 my-3">
                    <input value={formData.city} onChange={handleInputChange} name="city" type="text" placeholder="City" />
                  </div>
                  <div className="col-12 col-md-8 my-3">
                    <input value={formData.address} onChange={handleInputChange} name="address" required type="text" placeholder="Address" />
                  </div>
                  <div className="col-12 col-md-4 my-3">
                    <input value={formData.state} onChange={handleInputChange} name="state" required type="text" placeholder="State" />
                  </div>
                </div>
                <button type="submit" className="font-graebenbach submit-btn px-4 py-1 my-5 my-md-5">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
